import { axios, useAuth } from "@/contexts/auth"
import { useEffect, useState } from "react"
import { DEFAULT_SETTINGS } from "@/settings"
import { useSerialDevice } from "@/contexts/serial-device"
import { FILTER_10_BAND_ORDER, FILTER_12_BAND_ORDER, getFilterOrder } from "@/utils/equaliser"

export type Preset = {
  id: number
  name: string
  mode: string
  is_favourite: boolean
  config: object
  created_at: Date
  updated_at: Date
}

export function usePresets() {
  const [presets, setPresets] = useState<Preset[]>()
  const [activePreset, setActivePreset] = useState<Preset | null>(null)
  const { isAuthenticated } = useAuth()
  const { deviceHas12Bands } = useSerialDevice()
  
  // Select the filter order based on device capabilities
  const filterOrder = getFilterOrder(deviceHas12Bands)

  const sortFunction = (a: Preset, b: Preset) => {
    if (a.is_favourite !== b.is_favourite) {
      return a.is_favourite === true ? -1 : 1
    }
    return new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
  }

  const retrievePresets = async () => {
    if (isAuthenticated()) {
      try {
        const response = await axios.get("/api/equalizer/presets/")
        // Only process if we got a successful response
        if (response?.status === 200) {
          setPresets((prevPresets) => {
            const newPresets = response.data
            newPresets.sort(sortFunction)
            
            // Process each preset to match the device's capabilities
            newPresets.forEach((preset) => {
              // If any preset is missing the Preamp settings, add them
              if (typeof preset.config["Preamp"] === "undefined") {
                preset.config["Preamp"] = DEFAULT_SETTINGS["Configuration"]["DSP"]["RCA"]["Preamp"]
              }
              
              // Ensure the filters match the device's capabilities and are in the correct order
              preset.config = ensureCorrectFilterOrder(preset.config);
            })
            
            return newPresets
          })
        }
      } catch (error) {
        // API server might be down, just log the error and continue
        console.error("Failed to retrieve presets:", error)
      }
    }
  }

  const deletePreset = async (preset) => {
    if (isAuthenticated()) {
      const response = await axios.delete(`/api/equalizer/presets/${preset.id}/delete/`)
      if (response.status === 204) {
        if (activePreset && preset.id === activePreset?.id) {
          setActivePreset(null)
        }
        setPresets((prevPresets) => {
          const newPresets = prevPresets.filter((prevPreset) => prevPreset.id !== preset.id)
          newPresets.sort(sortFunction)
          return newPresets
        })
        return true
      }
    } else {
      console.warn("Cannot delete preset: user not authenticated")
      return false
    }
  }

  const ensureCorrectFilterOrder = (config) => {
    const orderedConfig = { Preamp: config.Preamp }
    
    // If device is using 12-band structure but preset is in 10-band format,
    // convert to 12-band format for internal use
    if (deviceHas12Bands) {
      // Handle backward compatibility for old filter names
      if (config["Lowshelf"] && !config["Lowshelf 1"]) {
        config["Lowshelf 1"] = config["Lowshelf"];
        delete config["Lowshelf"];
      }
      
      if (config["Highshelf"] && !config["Highshelf 1"]) {
        config["Highshelf 1"] = config["Highshelf"];
        delete config["Highshelf"];
      }
      
      // Add missing Lowshelf 2 and Highshelf 2 with default values if they don't exist
      if (!config["Lowshelf 2"]) {
        config["Lowshelf 2"] = DEFAULT_SETTINGS["Configuration"]["DSP"]["RCA"]["Lowshelf 2"];
      }
      
      if (!config["Highshelf 2"]) {
        config["Highshelf 2"] = DEFAULT_SETTINGS["Configuration"]["DSP"]["RCA"]["Highshelf 2"];
      }
    } 
    // If device is using 10-band structure but preset is in 12-band format,
    // convert to 10-band format for display
    else if (!deviceHas12Bands) {
      // Convert Lowshelf 1 -> Lowshelf
      if (config["Lowshelf 1"] && !config["Lowshelf"]) {
        config["Lowshelf"] = config["Lowshelf 1"];
        delete config["Lowshelf 1"];
      }
      
      // Convert Highshelf 1 -> Highshelf
      if (config["Highshelf 1"] && !config["Highshelf"]) {
        config["Highshelf"] = config["Highshelf 1"];
        delete config["Highshelf 1"];
      }
      
      // Remove Lowshelf 2 and Highshelf 2 as they're not used in 10-band mode
      delete config["Lowshelf 2"];
      delete config["Highshelf 2"];
    }
    
    // Use the appropriate filter order based on device capabilities
    filterOrder.forEach((filterType) => {
      if (config[filterType]) {
        orderedConfig[filterType] = config[filterType]
      } else {
        console.warn(`Missing filter in preset: ${filterType}`)
        // Add default values for missing filters
        if (filterType === "Lowshelf" || filterType === "Highshelf" || 
            filterType.startsWith("Lowshelf") || filterType.startsWith("Highshelf")) {
          // Default settings for shelf filters
          orderedConfig[filterType] = {
            Gain: { Min: -30, Max: 30, Current: 0 },
            Frequency: { 
              Min: 20, 
              Max: 20000, 
              Current: filterType === "Lowshelf" || filterType === "Lowshelf 1" ? 80 :
                      filterType === "Lowshelf 2" ? 200 :
                      filterType === "Highshelf" || filterType === "Highshelf 1" ? 8000 : 12000
            },
            Q: { Min: 0.1, Max: 3.0, Current: 0.707 },
          }
        } else {
          // Default settings for peaking filters
          orderedConfig[filterType] = {
            Gain: { Min: -30, Max: 30, Current: 0 },
            Frequency: { Min: 20, Max: 20000, Current: 1000 },
            Q: { Min: 0.1, Max: 50, Current: 0.707 },
          }
        }
      }
    })
    
    return orderedConfig
  }

  const createPreset = async ({
    name,
    mode,
    config,
  }: {
    name: string
    mode: string
    config: object
  }): Promise<Preset | false> => {
    if (isAuthenticated()) {
      // Ensure config has the correct filter structure 
      const orderedConfig = ensureCorrectFilterOrder(config)
      
      const { data: createdPreset } = await axios.post(`/api/equalizer/presets/`, {
        name,
        mode,
        config: orderedConfig,
      })
      setActivePreset(createdPreset)
      setPresets((prevPresets) => {
        const newPresets = prevPresets ? [...prevPresets] : []
        newPresets.push(createdPreset)
        newPresets.sort(sortFunction)
        return newPresets
      })
      return createdPreset
    } else {
      console.warn("Cannot create preset: user not authenticated")
    }
    return false
  }

  const updateActivePreset = async (preset): Promise<Preset | false> => {
    if (isAuthenticated()) {
      // Ensure preset has the correct filter structure
      const orderedConfig = ensureCorrectFilterOrder(preset.config)
      
      const { data: updatedPreset } = await axios.patch(
        `/api/equalizer/presets/${preset.id}/update/`,
        { ...preset, config: orderedConfig }
      )
      setActivePreset(updatedPreset)
      setPresets((prevPresets) => {
        if (!prevPresets) return [updatedPreset]
        
        const newPresets = prevPresets.map((oldPreset) =>
          oldPreset.id === updatedPreset.id ? updatedPreset : oldPreset
        )
        newPresets.sort(sortFunction)
        return newPresets
      })
      return updatedPreset
    } else {
      console.warn("Cannot update preset: user not authenticated")
    }
    return false
  }

  //   When a user's authentication status changes, potentially fetch presets
  useEffect(() => {
    retrievePresets()
  }, [isAuthenticated])

  return {
    presets,
    activePreset,
    setActivePreset: (preset) => {
      if (preset) {
        // Ensure preset has the correct filter structure based on connected device
        const orderedConfig = ensureCorrectFilterOrder(preset.config)
        setActivePreset({ ...preset, config: orderedConfig })
      } else {
        setActivePreset(null)
      }
    },
    deletePreset,
    createPreset,
    updateActivePreset,
  }
}
