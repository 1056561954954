import { createContext, useContext, useEffect, useState } from "react"
import { useSerial } from "@/hooks/serial"
import { useQueue } from "@/hooks/queue"
import { toast } from "react-toastify"
import { DEFAULT_SETTINGS } from "@/settings"

const DEVICE_UPDATE_TOAST_ID = "device-update-toast"

class SerialDeviceError extends Error {}

interface SerialDeviceContextType {
  status: string
  request: () => Promise<boolean>
  getSettings: () => Promise<any>
  saveSettings: () => Promise<any>
  updateSettings: (settings: any) => Promise<any>
  auditionSettings: (settings: any) => Promise<any>
  refreshSettings?: () => Promise<void>
  portRef: React.MutableRefObject<any>
  disconnect: () => Promise<void>
  dspSettings: any
  setDspSettings: (settings: any) => void
  cachedGains: Record<string, number>
  setCachedGains: (gains: Record<string, number>) => void
  deviceHas12Bands: boolean
}

const SerialDeviceContext = createContext<SerialDeviceContextType | undefined>(undefined)

export function SerialDeviceProvider({ children }) {
  const serial = useSerial()
  const queue = useQueue({ maxSize: 10, pollTime: 200 })
  const [dspSettings, setDspSettings] = useState(DEFAULT_SETTINGS["Configuration"]["DSP"])
  const [cachedGains, setCachedGains] = useState<Record<string, number>>({})
  const [deviceHas12Bands, setDeviceHas12Bands] = useState(true)

  /**
   * Execute a command when received from the queue
   *
   * @param command
   * @param deferredPromise
   * @returns
   */
  const onCommandReceived = async (command, deferredPromise) => {
    console.debug(`Device: executing command:`, command)
    return new Promise<void>((resolve) => {
      let timeout

      // Subscribe to serial port to read the next message
      const unsubscribe = serial.subscribe((data) => {
        clearTimeout(timeout)
        unsubscribe()
        deferredPromise.resolve(data)
        resolve()
      })

      // Set a timeout
      timeout = setTimeout(() => {
        console.warn("Device: command timed out")
        unsubscribe()
        deferredPromise(false)
        resolve()
      }, 5000)

      // Send command
      serial.write(command)
    })
  }

  const sendCommand = async (command) => {
    // Push command to the queue
    const onResponseReceived = await queue.push(command)
    
    if (!onResponseReceived) {
      throw new SerialDeviceError("Failed to queue command")
    }

    // Wait for reply from the serial device
    const response = await onResponseReceived.promise
    if (response["Status"] === true) {
      return response
    } else {
      throw new SerialDeviceError("Command error updating settings")
    }
  }

  const getSettings = async () => {
    const response = await sendCommand({
      Product: "JDS Labs Element IV",
      Action: "Describe",
    })
    
    // Detect if device has 12 bands by checking the structure of the response
    const has12Bands = 
      (response as any)?.Configuration?.DSP?.RCA?.["Lowshelf 1"] !== undefined ||
      (response as any)?.Configuration?.DSP?.RCA?.["Lowshelf 2"] !== undefined ||
      (response as any)?.Configuration?.DSP?.RCA?.["Highshelf 1"] !== undefined ||
      (response as any)?.Configuration?.DSP?.RCA?.["Highshelf 2"] !== undefined;
    
    const has10Bands = 
      (response as any)?.Configuration?.DSP?.RCA?.["Lowshelf"] !== undefined ||
      (response as any)?.Configuration?.DSP?.RCA?.["Highshelf"] !== undefined;
    
    // If 12-band structure is detected, use it; otherwise fall back to 10-band
    setDeviceHas12Bands(has12Bands && !has10Bands);
    
    return response;
  }

  /**
   * Format outgoing DSP settings to match device's expected filter structure
   * 
   * @param settings The settings object to format
   * @returns Formatted settings object
   */
  const formatOutgoingDspSettings = (settings: any): any => {
    // If device uses 12-band structure, no conversion needed
    if (deviceHas12Bands) {
      return settings;
    }
    
    // Clone settings to avoid mutating the original
    const formattedSettings = JSON.parse(JSON.stringify(settings));
    
    // If we're updating DSP settings
    if (formattedSettings.Configuration?.DSP) {
      // Handle both RCA and Headphone modes
      for (const mode of ["RCA", "Headphone"]) {
        if (formattedSettings.Configuration.DSP[mode]) {
          const dspConfig = formattedSettings.Configuration.DSP[mode];
          
          // Convert Lowshelf 1 -> Lowshelf for 10-band devices
          if (dspConfig["Lowshelf 1"]) {
            dspConfig["Lowshelf"] = dspConfig["Lowshelf 1"];
            delete dspConfig["Lowshelf 1"];
          }
          
          // Remove Lowshelf 2 as it's not supported in 10-band devices
          if (dspConfig["Lowshelf 2"]) {
            delete dspConfig["Lowshelf 2"];
          }
          
          // Convert Highshelf 1 -> Highshelf for 10-band devices
          if (dspConfig["Highshelf 1"]) {
            dspConfig["Highshelf"] = dspConfig["Highshelf 1"];
            delete dspConfig["Highshelf 1"];
          }
          
          // Remove Highshelf 2 as it's not supported in 10-band devices
          if (dspConfig["Highshelf 2"]) {
            delete dspConfig["Highshelf 2"];
          }
        }
      }
    }
    
    return formattedSettings;
  }

  const saveSettings = async (): Promise<any> => {
    const response = await sendCommand({
      Product: "JDS Labs Element IV",
      "Format Output": true,
      Action: "Save",
    })
    if (response["Status"] === true) {
      toast.success("Settings saved to flash storage", {
        toastId: DEVICE_UPDATE_TOAST_ID,
        updateId: DEVICE_UPDATE_TOAST_ID,
      })
      return response
    } else {
      throw new SerialDeviceError("Command error saving settings")
    }
  }

  const auditionSettings = async (settingsUpdate: any): Promise<any> => {
    // Format settings to match device's filter structure
    const formattedSettings = formatOutgoingDspSettings(settingsUpdate);
    
    const response = await sendCommand({
      Product: "JDS Labs Element IV",
      "Format Output": true,
      Action: "Audition",
      ...formattedSettings,
    })
    if (response["Status"] === true) {
      toast.success("Settings applied (not saved)", {
        autoClose: 2000,
        toastId: DEVICE_UPDATE_TOAST_ID,
        updateId: DEVICE_UPDATE_TOAST_ID,
      })
      return response
    } else {
      throw new SerialDeviceError("Command error auditioning settings")
    }
  }
  /**
   * Send an update to the device
   *
   * @param formattedSettings a nested object that contains only the settings we
   * want to update, for example:
   * {
   *   "UI": {
   *     "Screen Timeout": "5 Seconds"
   *   }
   * }
   * @returns
   */
  const updateSettings = async (settingsUpdate: any): Promise<any> => {
    // Format settings to match device's filter structure
    const formattedSettings = formatOutgoingDspSettings(settingsUpdate);
    
    const response = await sendCommand({
      Product: "JDS Labs Element IV",
      "Format Output": true,
      Action: "Update",
      ...formattedSettings,
    })
    if (response["Status"] === true) {
      // Check if this is a mode change update
      if (settingsUpdate.Configuration?.General?.["Output Mode"]) {
        const newMode = settingsUpdate.Configuration.General["Output Mode"]
        toast.success(`Changed Output to ${newMode}`, {
          toastId: DEVICE_UPDATE_TOAST_ID,
          updateId: DEVICE_UPDATE_TOAST_ID,
        })
      } else if (settingsUpdate.Configuration?.General?.["Input Mode"]) {
        const newMode = settingsUpdate.Configuration.General["Input Mode"]
        toast.success(`Changed Input to ${newMode}`, {
          toastId: DEVICE_UPDATE_TOAST_ID,
          updateId: DEVICE_UPDATE_TOAST_ID,
        })
      } else {
        toast.success("Settings Applied & Saved", {
          toastId: DEVICE_UPDATE_TOAST_ID,
          updateId: DEVICE_UPDATE_TOAST_ID,
        })
      }
      return response
    } else {
      throw new SerialDeviceError("Command error updating settings")
    }
  }

  /**
   * Subscribe to the queue and hook up callback for new commands
   *
   */
  useEffect(() => {
    const unsubscribe = queue.subscribe("executor", onCommandReceived)

    // Subscribe to serial events
    const serialUnsubscribe = serial.subscribe((data) => {
      if (data.Action === "Disconnect") {
        // Clear any pending commands from the queue
        queue.clear()
        
        // Reset DSP settings to default values
        setDspSettings(DEFAULT_SETTINGS["Configuration"]["DSP"])
        
        // Clear any cached gains
        setCachedGains({})
      }
    })

    return () => {
      if (unsubscribe) unsubscribe()
      if (serialUnsubscribe) serialUnsubscribe()
    }
  }, [])

  return (
    <SerialDeviceContext.Provider
      value={{ 
        ...serial, 
        getSettings, 
        updateSettings, 
        saveSettings, 
        auditionSettings,
        portRef: serial.portRef,
        dspSettings,
        setDspSettings,
        cachedGains,
        setCachedGains,
        deviceHas12Bands
      }}
    >
      {children}
    </SerialDeviceContext.Provider>
  )
}

export function useSerialDevice() {
  const context = useContext(SerialDeviceContext)
  if (!context) {
    throw new Error("useSerialDevice must be used within SerialDeviceProvider")
  }
  return context
}
