import Button from "@/components/Button"
import { MODE_HEADPHONES, MODE_RCA, DEVICE_CONNECTED } from "@/constants"
import { useAuth } from "@/contexts/auth"
import { useSerialDevice } from "@/contexts/serial-device"
import { ReactComponent as HeadphoneIcon } from "@/icons/headphone-icon.svg"
import { ReactComponent as RCAIcon } from "@/icons/rca-icon.svg"
import { ReactComponent as SearchIcon } from "@/icons/search-icon.svg"
import { ReactComponent as StarIcon } from "@/icons/star-icon.svg"
import { ReactComponent as USBIcon } from "@/icons/usb-icon.svg"
import { ReactComponent as TOSLINKIcon } from "@/icons/toslink-icon.svg"
import WarningCallout from "@/components/WarningCallout"
import cx from "classnames"
import { Link, useNavigate } from "react-router-dom"
import { useState, useEffect } from "react"
import { toast } from "react-toastify"

const ModePanel = ({
  mode,
  presets,
  activePreset,
  onModeChanged,
  onPresetSeleceted,
  onDisableEqClicked,
  deviceSettings,
}) => {
  const { isAuthenticated } = useAuth()
  return (
    <>
      <ModePanelHeader mode={mode} onModeChanged={onModeChanged} deviceSettings={deviceSettings} />
      <section className="mt-6">
        {isAuthenticated() ? (
          <>
            <PresetsPanel
              presets={presets}
              activePreset={activePreset}
              mode={mode}
              onPresetSeleceted={onPresetSeleceted}
            />
          </>
        ) : (
          <LoggedOutWarning />
        )}
      </section>
      <ModePanelFooter onDisableEqClicked={onDisableEqClicked} />
    </>
  )
}

const ModePanelHeader = ({ mode, onModeChanged, deviceSettings }) => {
  const { updateSettings, status: deviceStatus } = useSerialDevice()
  const [currentInputMode, setCurrentInputMode] = useState(deviceSettings?.Configuration?.General?.["Input Mode"]?.Current || "USB")

  // Update currentInputMode when deviceSettings changes
  useEffect(() => {
    const newInputMode = deviceSettings?.Configuration?.General?.["Input Mode"]?.Current
    if (newInputMode) {
      setCurrentInputMode(newInputMode)
    }
  }, [deviceSettings])

  const onInputModeChanged = async (inputMode) => {
    if (deviceStatus === DEVICE_CONNECTED) {
      try {
        await updateSettings({
          Product: "JDS Labs Element IV",
          "Format Output": true,
          Action: "Update",
          Configuration: {
            General: {
              "Input Mode": inputMode
            }
          }
        })
        // Update local state after successful device update
        setCurrentInputMode(inputMode)
      } catch (error) {
        console.error("Error updating input mode:", error)
        toast.error("Error updating input mode")
      }
    }
  }

  return (
    <header>
      <h3 className="type-heading-base">Output Mode</h3>
      <div className="mb-6 mt-1">
        <div className="flex">
          <button
            className={cx(
              "type-body-base clickable flex flex-1 items-center py-6 font-medium",
              { "text-red": mode === MODE_HEADPHONES }
            )}
            onClick={() => {
              onModeChanged(MODE_HEADPHONES)
            }}
          >
            <HeadphoneIcon className="mr-4 w-5" />
            {MODE_HEADPHONES}
          </button>
          <button
            className={cx(
              "type-body-base clickable flex flex-1 items-center py-6 font-medium",
              { "text-red": mode === MODE_RCA }
            )}
            onClick={() => {
              onModeChanged(MODE_RCA)
            }}
          >
            <RCAIcon className="mr-4 w-8" />
            {MODE_RCA}
          </button>
        </div>
        <div className="relative h-1 w-full overflow-hidden bg-tundora">
          <div
            className={cx("absolute top-0 h-full w-1/2 bg-red transition-all ease-out", {
              "-left-5": mode === MODE_HEADPHONES,
              "left-1/2": mode === MODE_RCA,
            })}
          ></div>
        </div>
      </div>

      <div className="mb-6">
        <h3 className="type-heading-base">Input Mode</h3>
        <div className="mt-2">
          <div className="flex">
            <button
              className={cx(
                "type-body-base clickable flex flex-1 items-center py-6 font-medium text-silver",
                { "text-red": currentInputMode === "USB" }
              )}
              onClick={() => onInputModeChanged("USB")}
            >
              <USBIcon className="mr-4 w-5" />
              USB
            </button>
            <button
              className={cx(
                "type-body-base clickable flex flex-1 items-center py-6 font-medium text-silver",
                { "text-red": currentInputMode === "SPDIF" }
              )}
              onClick={() => onInputModeChanged("SPDIF")}
            >
              <TOSLINKIcon className="mr-4 w-5" />
              {deviceSettings?.Configuration?.General?.["SPDIF mode"]?.Current === "ESS S/PDIF" 
                ? "S/PDIF (No DSP)"
                : "S/PDIF"
              }
            </button>
          </div>
          <div className="relative h-1 w-full overflow-hidden bg-tundora">
            <div
              className={cx("absolute top-0 h-full w-1/2 bg-red transition-all ease-out", {
                "-left-5": currentInputMode === "USB",
                "left-1/2": currentInputMode === "SPDIF",
              })}
            ></div>
          </div>
        </div>
      </div>

      <h4 className="type-heading-base font-bold">My EQ Presets</h4>
    </header>
  )
}

const ModePanelFooter = ({ onDisableEqClicked }) => {
  return (
    <footer>
      <Button
        className="mt-4"
        theme="primary"
        size="small"
        outline
        onClick={() => onDisableEqClicked()}
      >
        Clear All EQ Bands
      </Button>
    </footer>
  )
}

const PresetsPanel = ({ presets, activePreset, mode, onPresetSeleceted }) => {
  const [filterText, setFilterText] = useState("")

  const modePresets = presets
    ? presets.filter((preset) => preset.mode.toLowerCase() === mode.toLowerCase())
    : []

  const filteredModePresets = modePresets.filter((preset) =>
    preset.name.toLowerCase().includes(filterText.toLowerCase())
  )

  if (!presets) {
    return <></>
  }

  return (
    <div>
      {modePresets.length > 0 && (
        <div className="relative mb-5 mt-2">
          <SearchIcon className="absolute left-0 top-1/2 h-3 w-3 -translate-y-1/2 text-silver" />
          <input
            type="text"
            className="h-10 w-full border-b border-silver bg-transparent pl-5 transition-colors focus:border-white"
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
          />
        </div>
      )}
      <ul className="space-y-3">
        {modePresets.length === 0 && (
          <p className="text-sm font-normal text-silver">No presets saved</p>
        )}
        {modePresets.length > 0 && filterText.length > 0 && filteredModePresets.length === 0 && (
          <p className="text-sm font-normal text-silver">No presets found</p>
        )}
        {filteredModePresets.map((preset, index) => {
          return (
            <li key={index}>
              <button
                className={cx(
                  "type-body-sm clickable flex w-full items-center rounded-md border px-4 py-2 font-medium transition-colors",
                  activePreset && preset.id === activePreset.id
                    ? "border-red"
                    : "border-mine-shaft hover:border-silver"
                )}
                onClick={() => onPresetSeleceted(preset)}
              >
                {preset.is_favourite && <StarIcon className="mr-4 h-4 w-4 [&>path]:fill-yellow [&>path]:stroke-none" />}
                {preset.name}
              </button>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

const LoggedOutWarning = () => {
  const navigate = useNavigate()
  return (
    <WarningCallout
      heading={<h2>An account is required to save presets</h2>}
      content={
        <>
          <p>
            Your EQ presets are saved to your account and will appear here when you sign in.
            Clicking a preset loads the EQ settings to your Element IV.
          </p>
          <p>
            <Button
              theme="secondary"
              size="small"
              outline
              onClick={() => {
                navigate("/signup")
              }}
            >
              Create an account
            </Button>
          </p>
          <p className="text-xs">
            Already have an account?{" "}
            <Link className="font-bold underline" to={{ pathname: "/login" }}>
              Sign in
            </Link>
          </p>
        </>
      }
    ></WarningCallout>
  )
}

export default ModePanel
