/**
 * Equaliser helper functions. These are primarily helper functions around the
 * custom JSON settings format that we use for describing the current state
 * of the equaliser. Be aware that these settings are only a subset of the full
 * device settings, or even the DSP settings.
 *
 * They look like:
 *
 *  {
 *   "Lowshelf": {
 *     "Frequency": {
 *       "Min": -30,
 *       "Max": 10000,
 *       "Current": 200
 *     },
 *     ...
 *   },
 *   ...
 * }
 *
 * Where each object in the JSON represents a band of the equaliser.
 *
 */

import { DEFAULT_SETTINGS } from "@/settings"

// Regex pattern for matching the preamp text settings in a Squiglink preset
const PREAMP_TEXT_PATTERN = /Preamp:\s*([-+]?[\d.]+) dB/
// Regex pattern for matching a filter line in the Squiglink preset
const FILTER_TEXT_PATTERN =
  /^Filter\s(\d+):\s(ON|OFF)\s(LSC|LS|PK|HSC|HS)\sFc\s(\d+(?:\.\d+)?)\sHz\sGain\s([-+]?\d+(?:\.\d+)?)\sdB\sQ\s(\d+(?:\.\d+)?)$/
// Regex for matching the peaking filter name in a JSON EQ settings object
const PEAKING_PATTERN = /^Peaking [1-8]$/

// Define 12-band filter order
export const FILTER_12_BAND_ORDER = [
  "Lowshelf 1",
  "Lowshelf 2",
  "Peaking 1",
  "Peaking 2",
  "Peaking 3",
  "Peaking 4",
  "Peaking 5",
  "Peaking 6",
  "Peaking 7",
  "Peaking 8",
  "Highshelf 1",
  "Highshelf 2",
]

// Define 10-band filter order
export const FILTER_10_BAND_ORDER = [
  "Lowshelf",
  "Peaking 1",
  "Peaking 2",
  "Peaking 3",
  "Peaking 4",
  "Peaking 5",
  "Peaking 6",
  "Peaking 7",
  "Peaking 8",
  "Highshelf",
]

// Helper function to get the current filter order based on whether 12 bands are available
export const getFilterOrder = (has12Bands) => {
  const order = has12Bands ? FILTER_12_BAND_ORDER : FILTER_10_BAND_ORDER;
  console.debug(`Using ${has12Bands ? '12-band' : '10-band'} filter order:`, order);
  return order;
}

/**
 * Validate EQ settings. Our EQ settings are a JSON object that describe the
 * state of the equaliser. They look like:
 *
 * {
 *   "Lowshelf": {
 *     "Frequency": {
 *       "Min": -30,
 *       "Max": 10000,
 *       "Current": 200
 *     },
 *     ...
 *   },
 *   ...
 * }
 *
 *
 * @param eqSettings the EQ settings JSON object form the device or the EQ page
 * @returns array of errors if the eq settings are invalid, otherwise an empty
 * array if everything is OK
 */
const validateEqSettings = (eqSettings) => {
  const domainKeys = ["Gain", "Frequency", "Q"]
  const valueKeys = ["Min", "Max", "Current"]
  const validShelfFilters = ["Highshelf", "Lowshelf", "Highshelf 1", "Highshelf 2", "Lowshelf 1", "Lowshelf 2"]
  const errors = Object.entries(eqSettings).reduce((errors: string[], entry, i) => {
    const [key, value] = entry
    if (key === "Preamp") {
      return []
    } else if (validShelfFilters.includes(key) || PEAKING_PATTERN.test(key)) {
      const errors = []
      // Check keys for filters
      domainKeys.forEach((k1) => {
        if (typeof value[k1] === "undefined") {
          errors.push(`Key '${k1}' missing from '${key}'`)
        } else {
          // Check keys on each individual filter
          valueKeys.forEach((k2) => {
            if (typeof value[k1] === "undefined") {
              errors.push(`Key '${k2}' missing from '${k1}'`)
            } else {
              // Check type of min/max/current
              if (typeof value[k1][k2] !== "number") {
                errors.push(`Key '${k2}' in '${k1}' is not a number (${value[k1][k2]})`)
              } else {
                // Check that the value is within bounds
                if (
                  k2 === "Current" &&
                  (value[k1][k2] > value[k1]["Max"] || value[k1][k2] < value[k1]["Min"])
                ) {
                  errors.push(`Value '${value[k1][k2]} ('${k2}' in '${k1}') is invalid`)
                }
              }
            }
          })
        }
      })
      return errors
    } else {
      return [...errors, `'${key}' is not a valid key for EQ settings`]
    }
  }, [])
  return errors
}

/**
 * Validate URL parameter settings
 * 
 * @param queryParam The URL parameter to validate
 * @param has12Bands Whether the device has 12 bands (true) or 10 bands (false)
 * @returns Array of error messages, empty if valid
 */
const validateUrlParamSettings = (queryParam: string, has12Bands = true): string[] => {
  const errors = []

  console.debug(`Validating URL param for ${has12Bands ? '12-band' : '10-band'} device:`, queryParam);

  // Handle band count prefix if it exists
  let processedParam = queryParam;
  if (processedParam.startsWith("10b:") || processedParam.startsWith("12b:")) {
    const prefix = processedParam.substring(0, 4);
    processedParam = processedParam.substring(4);
    console.debug(`Detected band prefix: ${prefix}, stripping for validation`);
  }

  const validTypes = ["LSC", "LS", "HSC", "HS", "PK"]
  const eqTokens = processedParam.split("|")
  
  console.debug(`Found ${eqTokens.length} EQ tokens after splitting`);
  
  // Make the band count validation more flexible to allow for compatible presets
  if (eqTokens.length < 8) { // Minimum number of bands for both 10 and 12 band modes
    errors.push(`Too few bands in the URL (minimum 8 required)`)
    return errors
  }

  if (has12Bands && eqTokens.length > 12) {
    errors.push(`Too many bands in the URL (maximum 12 allowed for 12-band mode)`)
    return errors
  }

  if (!has12Bands && eqTokens.length > 10) {
    errors.push(`Too many bands in the URL (maximum 10 allowed for 10-band mode)`)
    return errors
  }

  // Count each type of filter
  let lscCount = 0;
  let hscCount = 0;
  let pkCount = 0;

  eqTokens.forEach((token, i) => {
    const bandTokens = token.split(",")
    if (bandTokens.length !== 4) {
      errors.push(`Band ${i + 1} is invalid (must have 4 values)`)
      return
    }
    const [type, gain, frequency, qFactor] = bandTokens
    
    // Count filter types
    if (type === "LSC" || type === "LS") lscCount++;
    if (type === "HSC" || type === "HS") hscCount++;
    if (type === "PK") pkCount++;
    
    if (!validTypes.includes(type)) {
      errors.push(`Type must be one of ${validTypes.toString()} (for band ${i + 1})`)
      return
    }
    if (isNaN(parseFloat(gain)) || parseFloat(gain) < -30 || parseFloat(gain) > 30) {
      errors.push(`Gain in band ${i + 1} must be a number between -30 and 30`)
      return
    }
    if (isNaN(parseFloat(frequency)) || parseFloat(frequency) < 20 || parseFloat(frequency) > 20000) {
      errors.push(`Frequency in band ${i + 1} must be a number between 20 and 20000`)
      return
    }
    if (isNaN(parseFloat(qFactor)) || parseFloat(qFactor) < 0.1 || parseFloat(qFactor) > 10) {
      errors.push(`Q factor in band ${i + 1} must be a number between 0.1 and 10`)
      return
    }
  })
  
  // Log the filter type counts for debugging
  console.debug(`Filter counts in URL: LSC=${lscCount}, HSC=${hscCount}, PK=${pkCount}`);
  
  // Check for expected counts based on band mode
  if (has12Bands) {
    // 12-band mode should have 2 LSC filters, 2 HSC filters, and 8 PK filters
    if (lscCount !== 2) {
      console.warn(`Expected 2 LSC filters for 12-band mode, got ${lscCount}`);
    }
    if (hscCount !== 2) {
      console.warn(`Expected 2 HSC filters for 12-band mode, got ${hscCount}`);
    }
  } else {
    // 10-band mode should have 1 LSC filter, 1 HSC filter, and 8 PK filters
    if (lscCount !== 1) {
      console.warn(`Expected 1 LSC filter for 10-band mode, got ${lscCount}`);
    }
    if (hscCount !== 1) {
      console.warn(`Expected 1 HSC filter for 10-band mode, got ${hscCount}`);
    }
  }
  
  // Peaking filter count should be 8 for both modes
  if (pkCount !== 8) {
    console.warn(`Expected 8 PK filters, got ${pkCount}`);
  }

  return errors
}

/**
 * Validate Squiglink-like text settings.
 *
 * - Must have a preamp line
 * - Only 1 low shelf filter
 * - Only 1 high shelf filter
 * - Max 8 peaking filters
 * - Max 10 filters in total
 * - Format of filters must match regex
 * @param text
 */
const validateTextSettings = (text) => {
  const lines = text
    .split("\n")
    .map((line) => line.trim())
    .filter(Boolean)

  if (!PREAMP_TEXT_PATTERN.test(lines[0])) {
    return ["The first line must be a preamp setting e.g. 'Preamp: -8.5 dB'"]
  }

  const filterLines = lines.splice(1)

  if (filterLines.length > 10) {
    return [`There is a limit of 10 filters, your file includes ${filterLines.length}`]
  }

  let errors = []
  let lowshelfFilterCount = 0
  let highshelfFilterCount = 0
  let peakingFilterCount = 0

  filterLines.forEach((line, i) => {
    const match = line.match(FILTER_TEXT_PATTERN)

    if (!match) {
      errors.push(`Line ${i + 1} is incorrectly formatted`)
      return
    }

    let [_, filterNumber, status, filterType, frequency, gain, qFactor] = match

    frequency = parseInt(frequency)
    if (frequency === 0) {
      errors.push(
        `Filter ${filterNumber} has frequency = 0 Hz. Please delete this unused filter from your import file.`
      )
      return
    }

    if (frequency > 20000 || frequency < 20) {
      errors.push(`Frequency on line ${i + 1} must be between 20 and 20,000 Hz`)
      return
    }

    qFactor = parseFloat(qFactor)
    if (qFactor === 0) {
      errors.push(
        `Filter ${filterNumber} has Q = 0. Please delete this unused filter from your import file.`
      )
      return
    }

    if (filterType === "LSC" || filterType === "LS") {
      if (lowshelfFilterCount >= 2) {
        errors.push("More than 2 lowshelf filters found. Only 2 are supported.")
        return
      }
      lowshelfFilterCount++
    } else if (filterType === "HSC" || filterType === "HS") {
      if (highshelfFilterCount >= 2) {
        errors.push("More than 2 highshelf filters found. Only 2 are supported.")
        return
      }
      highshelfFilterCount++
    } else if (filterType === "PK") {
      if (peakingFilterCount >= 8) {
        errors.push("Maximum of 8 peaking filters allowed.")
        return
      }
      peakingFilterCount++
    }

    frequency = parseInt(frequency)
    if (frequency > 20000 || frequency < 20) {
      errors.push(`Frequency on line ${i + 1} must be between 20 and 20,000 Hz`)
      return
    }

    gain = parseInt(gain)
    if (gain > 30 || gain < -30) {
      errors.push(`Gain on line ${i + 1} must be between -30 and 30 dB`)
      return
    }

    qFactor = parseFloat(qFactor)
    if (qFactor < 0.1 || isNaN(qFactor)) {
      // Common minimum for all filter types
      errors.push(
        `Invalid Q of ${qFactor} on filter ${i + 1}. Q must be between least 0.1. Try a default value of 0.707.`
      )
      return
    }

    // Different max Q based on filter type
    if (filterType === "PK" && qFactor > 50) {
      errors.push(
        `Invalid Q of ${qFactor} on filter ${i + 1}. Peaking filters must have Q between 0.1 and 50`
      )
      return
    } else if ((filterType === "LSC" || filterType === "LS" || filterType === "HSC" || filterType === "HS") && qFactor > 3) {
      errors.push(
        `Invalid Q of ${qFactor} on filter ${i + 1}. Shelf filters must have Q between 0.1 and 3`
      )
      return
    }
  })

  return errors
}

const getShortFilterType = (longFilterType) => {
  if (longFilterType === "Highshelf" || longFilterType === "Highshelf 1" || longFilterType === "Highshelf 2") {
    return "HSC"
  } else if (longFilterType === "Lowshelf" || longFilterType === "Lowshelf 1" || longFilterType === "Lowshelf 2") {
    return "LSC"
  } else if (longFilterType.startsWith("Peaking")) {
    return "PK"
  }
  new Error(`Error converting '${longFilterType}' to short filter type`)
}

const getLongFilterType = (shortFilterType, filterNumber) => {
  if (shortFilterType === "LSC" || shortFilterType === "LS") {
    if (filterNumber) {
      return `Lowshelf ${filterNumber}`
    }
    return "Lowshelf 1"
  } else if (shortFilterType === "HSC" || shortFilterType === "HS") {
    if (filterNumber) {
      return `Highshelf ${filterNumber}`
    }
    return "Highshelf 1"
  } else if (shortFilterType === "PK") {
    if (filterNumber) {
      return `Peaking ${filterNumber}`
    }
    return `Peaking`
  }
  new Error(`Error converting '${shortFilterType}' to long filter type`)
}

const createEqSettingValue = (gain, frequency, qFactor, filterType) => {
  return {
    Gain: {
      Min: -30,
      Max: 30,
      Current: Math.round(parseFloat(gain) * 100) / 100, // Round to 2 decimal places
    },
    Frequency: {
      Min: 20,
      Max: 20000,
      Current: Math.round(parseFloat(frequency)),
    },
    Q: {
      Min: 0.1,
      Max: filterType === "PK" ? 50 : 3,
      Current: Math.round(parseFloat(qFactor) * 1000) / 1000, // Round to 3 decimal places
    },
  }
}

/**
 * Convert our local EQ settings from the device (formatted as a JSON object)
 * into a Squiglink-like text file that can be exported by the user.
 *
 * Precision requirements:
 * - Frequency: Integer values only (e.g., "100 Hz")
 * - Gain: 1 decimal place (e.g., "-3.5 dB")
 * - Q Factor: 3 decimal places (e.g., "0.707")
 * 
 * @param eqSettings The EQ settings object
 * @param has12Bands Whether the device has 12 bands (true) or 10 bands (false)
 * @returns Text representation of the EQ settings
 */
const eqSettingsToText = (eqSettings, has12Bands = true) => {
  // Use the appropriate filter order based on device capabilities
  const filterOrder = getFilterOrder(has12Bands);

  const lines = filterOrder.map((key, i) => {
    // Try to get the value, with special handling for 10-band vs 12-band
    let value = eqSettings[key]; 
    
    // For 10-band devices, we need to handle mapping differently
    if (!has12Bands) {
      // Map generic filter names to specific numbered filters if needed
      if (key === "Lowshelf" && !value) {
        // Try to find a value in Lowshelf 1
        value = eqSettings["Lowshelf 1"] || eqSettings["Lowshelf"];
      } else if (key === "Highshelf" && !value) {
        // Try to find a value in Highshelf 1
        value = eqSettings["Highshelf 1"] || eqSettings["Highshelf"];
      }
    }
    
    if (!value) {
      console.warn(`Missing filter: ${key} for ${has12Bands ? '12-band' : '10-band'} device`);
      // Use the correct filter type and default frequency based on the slot position
      const filterType = getShortFilterType(key);
      // Get default frequency from DEFAULT_SETTINGS for this filter type
      let defaultFreq;
      try {
        // Try to get from exact key first
        defaultFreq = DEFAULT_SETTINGS.Configuration.DSP.Headphone[key]?.Frequency?.Current;
        if (!defaultFreq) {
          // Fallback values based on filter type
          if (key.includes("Lowshelf") || key === "Lowshelf") {
            defaultFreq = 80;
          } else if (key.includes("Highshelf") || key === "Highshelf") {
            defaultFreq = 12000;
          } else {
            defaultFreq = 1000; // Default for peaking filters
          }
        }
      } catch (e) {
        // Fallback if we can't access the default settings
        if (key.includes("Lowshelf") || key === "Lowshelf") {
          defaultFreq = 80;
        } else if (key.includes("Highshelf") || key === "Highshelf") {
          defaultFreq = 12000;
        } else {
          defaultFreq = 1000; // Default for peaking filters
        }
      }
      
      return `Filter ${i + 1}: OFF ${filterType} Fc ${defaultFreq} Hz Gain 0 dB Q 0.707`;
    }

    const status = value.Gain.Current === 0 ? "OFF" : "ON";
    let filterType = getShortFilterType(key);

    return (
      `Filter ${i + 1}: ${status} ${filterType} ` +
      `Fc ${Math.round(value.Frequency.Current)} Hz ` +
      `Gain ${value.Gain.Current.toFixed(1)} dB ` +
      `Q ${value.Q.Current.toFixed(3)}`
    );
  });

  // Add Preamp line at the beginning
  let preamp = 0;
  try {
    preamp = parseFloat(eqSettings.Preamp?.Gain?.Current || 0);
  } catch (e) {
    console.warn("Error parsing preamp value");
  } finally {
    lines.unshift(`Preamp: ${preamp.toFixed(1)} dB`);
  }

  return lines.join("\n");
}

/**
 * Convert Squiglink text presets to our own custom JSON format.
 *
 * These text presets are formatted like:
 *
 * Preamp: -2.9 dB
 * Filter 1: ON PK Fc 20 Hz Gain -1.6 dB Q 2.000
 * Filter 2: ON PK Fc 35 Hz Gain -6.0 dB Q 0.500
 * Filter 3: ON PK Fc 250 Hz Gain 2.1 dB Q 0.500
 * Filter 4: ON PK Fc 4000 Hz Gain -0.8 dB Q 2.000
 * Filter 5: ON PK Fc 5500 Hz Gain -3.7 dB Q 1.000
 * Filter 6: ON PK Fc 7100 Hz Gain 1.6 dB Q 1.000
 * Filter 7: ON PK Fc 8000 Hz Gain -3.0 dB Q 2.000
 * Filter 8: ON PK Fc 8300 Hz Gain -3.0 dB Q 2.000
 * Filter 9: ON PK Fc 10000 Hz Gain 5.6 dB Q 2.000
 * Filter 10: OFF PK Fc 0 Hz Gain 0.0 dB Q 0.000
 *
 * While the result will look like:
 *
 * {
 *   "Lowshelf": {
 *     "Frequency": {
 *       "Min": -30,
 *       "Max": 10000,
 *       "Current": 200
 *     },
 *     ...
 *   },
 *   ...
 * }
 *
 * @param text
 */
const textToEqSettings = (text, deviceHas12Bands = true) => {
  console.debug("Processing EQ text import:", text)

  // Get default DSP settings for Headphone output
  const defaultDSP = DEFAULT_SETTINGS.Configuration.DSP.Headphone

  // Initialize with default values from settings.ts
  const eqSettings = {
    Preamp: {
      Mode: { ...defaultDSP.Preamp.Mode },
      Gain: { ...defaultDSP.Preamp.Gain },
    },
    // Copy default filters from settings.ts
    "Lowshelf 1": { ...defaultDSP["Lowshelf 1"] },
    "Lowshelf 2": { ...defaultDSP["Lowshelf 2"] },
    "Peaking 1": { ...defaultDSP["Peaking 1"] },
    "Peaking 2": { ...defaultDSP["Peaking 2"] },
    "Peaking 3": { ...defaultDSP["Peaking 3"] },
    "Peaking 4": { ...defaultDSP["Peaking 4"] },
    "Peaking 5": { ...defaultDSP["Peaking 5"] },
    "Peaking 6": { ...defaultDSP["Peaking 6"] },
    "Peaking 7": { ...defaultDSP["Peaking 7"] },
    "Peaking 8": { ...defaultDSP["Peaking 8"] },
    "Highshelf 1": { ...defaultDSP["Highshelf 1"] },
    "Highshelf 2": { ...defaultDSP["Highshelf 2"] },
  }

  // For 10-band mode, create "Lowshelf" and "Highshelf" entries
  if (!deviceHas12Bands) {
    eqSettings["Lowshelf"] = { ...defaultDSP["Lowshelf 1"] };
    eqSettings["Highshelf"] = { ...defaultDSP["Highshelf 1"] };
  }

  const lines = text
    .split("\n")
    .map((line) => line.trim())
    .filter(Boolean)

  // Handle preamp
  const preampMatch = lines[0].match(PREAMP_TEXT_PATTERN)
  if (preampMatch) {
    const preampGain = parseFloat(preampMatch[1])
    console.debug(`Setting Preamp gain to ${preampGain}dB`)
    eqSettings.Preamp.Gain.Current = preampGain
  } else {
    console.warn("No Preamp setting found in import, retaining existing Preamp settings")
  }

  // Process filter lines
  const filterLines = lines.splice(1)
  let peakingCount = 1
  let lowshelfCount = 1
  let highshelfCount = 1

  // For 10-band mode, ensure we target the proper filter names
  const processFilter = (filterType, index) => {
    if (deviceHas12Bands) {
      // 12-band logic (unchanged)
      if (filterType === "LSC" || filterType === "LS") {
        if (lowshelfCount <= 2) {
          return `Lowshelf ${lowshelfCount++}`;
        }
      } else if (filterType === "HSC" || filterType === "HS") {
        if (highshelfCount <= 2) {
          return `Highshelf ${highshelfCount++}`;
        }
      } else if (filterType === "PK" && peakingCount <= 8) {
        return `Peaking ${peakingCount++}`;
      }
    } else {
      // 10-band logic
      if (filterType === "LSC" || filterType === "LS") {
        return "Lowshelf";
      } else if (filterType === "HSC" || filterType === "HS") {
        return "Highshelf";
      } else if (filterType === "PK" && peakingCount <= 8) {
        return `Peaking ${peakingCount++}`;
      }
    }
    return null;
  };

  filterLines.forEach((line, index) => {
    const match = line.match(FILTER_TEXT_PATTERN)
    if (match) {
      const [_, filterNumber, status, filterType, frequency, gain, qFactor] = match
      console.debug(`Processing filter ${filterNumber}: ${filterType} @ ${frequency}Hz`)

      // Skip bands with invalid frequency or Q
      if (parseFloat(frequency) === 0 || parseFloat(qFactor) === 0) {
        console.debug(`Skipping filter ${filterNumber} due to invalid frequency or Q value`)
        return
      }

      // Determine which slot to put this filter in
      let targetKey = processFilter(filterType, index);

      // Update the filter if we have a valid slot for it
      if (targetKey) {
        // Get the default filter to use as template - for 10-band mode, use appropriate defaults
        const defaultFilter = deviceHas12Bands ? 
          defaultDSP[targetKey] : 
          defaultDSP[targetKey.replace("Lowshelf", "Lowshelf 1").replace("Highshelf", "Highshelf 1")];
          
        const parsedGain = parseFloat(gain)
        const parsedFreq = parseFloat(frequency)
        const parsedQ = parseFloat(qFactor)

        console.debug(`Mapping to ${targetKey}:`, {
          gain: status === "ON" ? parsedGain : 0,
          freq: parsedFreq,
          Q: parsedQ,
        })

        eqSettings[targetKey] = {
          Gain: {
            ...defaultFilter.Gain,
            Current: status === "ON" ? parsedGain : 0,
          },
          Frequency: {
            ...defaultFilter.Frequency,
            Current: parsedFreq,
          },
          Q: {
            ...defaultFilter.Q,
            Current: parsedQ,
          },
        }

        // Validate Q is within bounds
        if (parsedQ > defaultFilter.Q.Max || parsedQ < defaultFilter.Q.Min) {
          console.warn(
            `Q value ${parsedQ} for ${targetKey} is outside allowed range [${defaultFilter.Q.Min}, ${defaultFilter.Q.Max}]`
          )
        }
      } else {
        console.warn(`Skipping filter ${filterNumber} - no available slot (type: ${filterType})`)
      }
    } else {
      console.warn(`Failed to parse filter line: ${line}`)
    }
  })

  console.debug("Final EQ settings before reordering:", eqSettings)
  const orderedSettings = reorderEqSettings(eqSettings, deviceHas12Bands)
  console.debug("Final ordered EQ settings:", orderedSettings)

  return orderedSettings
}

/**
 * Convert EQ settings to URL parameter
 * 
 * The URL param is a string of pipe-separated tokens. Each token represents an EQ band and
 * settings looks like "<type>,<gain>,<frequency>,<q factor>" where
 *
 * - <type> is the filter type and can be "LSC" (lowshelf), "HSC" (highshelf), "PK" (peaking)
 * - <gain> is the value of gain between -30 and 30
 * - <frequency> is between 20 and 20000
 * - <q factor> is between 0 and 100
 *
 * @param eqSettings The equalizer settings object
 * @param has12Bands Whether the device supports 12 bands (true) or 10 bands (false)
 * @returns URL parameter string
 */
const eqSettingsToURLParam = (eqSettings, has12Bands = true): string => {
  // Use filter order based on device capabilities
  const filterOrder = getFilterOrder(has12Bands);
  
  console.debug("Generating URL param for", has12Bands ? "12-band" : "10-band", "device");
  console.debug("Using filter order:", filterOrder);
  console.debug("Source EQ settings:", eqSettings);
  
  // Get entries from eqSettings but reorder them according to filter order
  const orderedEntries = filterOrder
    .filter(filterName => eqSettings[filterName])
    .map(filterName => [filterName, eqSettings[filterName]]);
  
  // Convert each entry to URL parameter format
  const arr = orderedEntries
    .filter(([key, val]) => key !== "Preamp")
    .map(([key, val]) => {
      let filterType = getShortFilterType(key);
      return `${filterType},${val["Gain"]["Current"]},${val["Frequency"]["Current"]},${val["Q"]["Current"]}`;
    });
  
  // Add prefix with band count information (10b or 12b)
  const bandPrefix = has12Bands ? "12b:" : "10b:";
  const result = bandPrefix + arr.join("|");
  
  console.debug("Generated URL param:", result);
  return result;
}

/**
 * Convert a "share" URL param to usable equaliser settings
 *
 * A "share" URL encodes the equaliser state in a query parameter and looks like:
 *
 * HSC,10,-3,1.02|PK,5,1,34|...
 *
 * This function takes the URL and splits it up. It then validates the input
 * to make sure it looks correct. If then returns a regular EQ setting object
 * in the format:
 *
 * {
 *   "Lowshelf": {
 *     "Gain": {
 *       "Min": -30,
 *       "Max": 30,
 *       "Current": 10
 *     },
 *     ...
 *   },
 *   ...
 * }
 * 
 * @param urlParam
 * @param has12Bands Whether the device supports 12 bands (true) or 10 bands (false)
 * @returns
 */
const urlParamToEqSettings = (urlParam: string, has12Bands = true) => {
  // Check for band count prefix (10b: or 12b:)
  let urlBandCount = null;
  let processedParam = urlParam;
  
  // Parse the band prefix if it exists
  if (urlParam.startsWith("10b:")) {
    urlBandCount = false; // 10 bands
    processedParam = urlParam.substring(4); // Remove prefix
  } else if (urlParam.startsWith("12b:")) {
    urlBandCount = true; // 12 bands
    processedParam = urlParam.substring(4); // Remove prefix
  }
  
  // If the URL specifies a band count, use it; otherwise use the provided device capability
  const effectiveBandCount = urlBandCount !== null ? urlBandCount : has12Bands;
  
  // If the URL is for a 10-band preset but the device is 12-band (or vice versa),
  // log a warning about potential compatibility issues
  const formatMismatch = urlBandCount !== null && urlBandCount !== has12Bands;
  if (formatMismatch) {
    console.warn(
      `Band count mismatch: URL is for ${urlBandCount ? '12' : '10'}-band preset, ` +
      `but device has ${has12Bands ? '12' : '10'} bands. Adapting preset to device capabilities.`
    );
  }

  const errors = validateUrlParamSettings(processedParam, effectiveBandCount)
  if (errors.length > 0) {
    throw new Error(errors.join(","))
  }

  console.debug("Processing URL param:", processedParam, "for", effectiveBandCount ? "12-band" : "10-band", "device")
  const eqTokens = processedParam.split("|")

  // Initialize settings object with device-appropriate filter order
  const eqSettings = {}
  
  // Add appropriate filter types based on device capabilities
  const filterOrder = getFilterOrder(has12Bands) // Use actual device capabilities for the structure
  filterOrder.forEach(filterType => {
    eqSettings[filterType] = null
  })

  let peakingCounter = 1
  // Use the effective band count when processing the URL parameters
  let lowshelfCounter = effectiveBandCount ? 1 : null // Only use counter for 12-band mode
  let highshelfCounter = effectiveBandCount ? 1 : null // Only use counter for 12-band mode

  // Process each token to build the eq settings
  for (let i = 0; i < eqTokens.length; i++) {
    const [filterType, gain, freq, q] = eqTokens[i].split(",")
    
    // Convert string values to numbers for later use
    const numGain = parseFloat(gain)
    const numFreq = parseFloat(freq)
    const numQ = parseFloat(q)

    // Check for valid filter type
    if (!["LSC", "HSC", "PK"].includes(filterType)) {
      console.warn(`Invalid filter type: ${filterType}, skipping entry ${i + 1}`)
      continue
    }

    // For debugging
    console.debug(`Processing token ${i+1}/${eqTokens.length}: ${filterType}, gain=${numGain}, freq=${numFreq}, Q=${numQ}`);

    // Handle different filter types based on format (10-band vs 12-band)
    if (filterType === "LSC") {
      if (effectiveBandCount) {
        // 12-band mode: Use Lowshelf 1 and Lowshelf 2
        // The token index doesn't matter, we use the counter to ensure proper assignment
        const targetKey = `Lowshelf ${lowshelfCounter}`;
        console.debug(`Assigning LSC filter to ${targetKey}`);
        eqSettings[targetKey] = createEqSettingValue(numGain, numFreq, numQ, filterType);
        
        // Only increment if we haven't reached the maximum
        if (lowshelfCounter < 2) {
          lowshelfCounter++;
        }
      } else {
        // 10-band mode: Use single Lowshelf
        eqSettings["Lowshelf"] = createEqSettingValue(numGain, numFreq, numQ, filterType)
      }
    } else if (filterType === "HSC") {
      if (effectiveBandCount) {
        // 12-band mode: Use Highshelf 1 and Highshelf 2
        // The token index doesn't matter, we use the counter to ensure proper assignment
        const targetKey = `Highshelf ${highshelfCounter}`;
        console.debug(`Assigning HSC filter to ${targetKey}`);
        eqSettings[targetKey] = createEqSettingValue(numGain, numFreq, numQ, filterType);
        
        // Only increment if we haven't reached the maximum
        if (highshelfCounter < 2) {
          highshelfCounter++;
        }
      } else {
        // 10-band mode: Use single Highshelf
        eqSettings["Highshelf"] = createEqSettingValue(numGain, numFreq, numQ, filterType)
      }
    } else if (filterType === "PK") {
      // Peaking filters (same for both 10 and 12 bands)
      if (peakingCounter <= 8) {
        eqSettings[`Peaking ${peakingCounter}`] = createEqSettingValue(numGain, numFreq, numQ, filterType)
        peakingCounter++
      }
    }
  }

  // Format adaptation: if loading a 10-band preset on a 12-band device,
  // copy the "Lowshelf" and "Highshelf" settings to the numbered versions
  if (!effectiveBandCount && has12Bands) {
    // We have a 10-band preset but need to adapt to 12-band format
    if (eqSettings["Lowshelf"]) {
      eqSettings["Lowshelf 1"] = { ...eqSettings["Lowshelf"] };
      // Make sure Lowshelf 2 exists with default settings
      if (!eqSettings["Lowshelf 2"]) {
        const defaultDSP = DEFAULT_SETTINGS.Configuration.DSP.Headphone;
        const defaultFreq = defaultDSP["Lowshelf 2"]?.Frequency?.Current || 200;
        eqSettings["Lowshelf 2"] = createEqSettingValue(0, defaultFreq, 0.707, "LSC");
      }
      delete eqSettings["Lowshelf"]; // Remove the 10-band key that doesn't exist in 12-band mode
    }
    
    if (eqSettings["Highshelf"]) {
      eqSettings["Highshelf 1"] = { ...eqSettings["Highshelf"] };
      // Make sure Highshelf 2 exists with default settings
      if (!eqSettings["Highshelf 2"]) {
        const defaultDSP = DEFAULT_SETTINGS.Configuration.DSP.Headphone;
        const defaultFreq = defaultDSP["Highshelf 2"]?.Frequency?.Current || 14000;
        eqSettings["Highshelf 2"] = createEqSettingValue(0, defaultFreq, 0.707, "HSC");
      }
      delete eqSettings["Highshelf"]; // Remove the 10-band key that doesn't exist in 12-band mode
    }
  }

  // Ensure all filter positions are filled - device requires all filters
  const defaultDSP = DEFAULT_SETTINGS.Configuration.DSP.Headphone

  // Fill in any missing filters with default values
  Object.keys(eqSettings).forEach((key) => {
    if (eqSettings[key] === null) {
      // Use default settings for the specific filter type
      if (key.startsWith("Lowshelf") || key === "Lowshelf") {
        // Get default frequency from DEFAULT_SETTINGS for this filter type
        const defaultFreq = defaultDSP[key]?.Frequency?.Current || 80
        eqSettings[key] = createEqSettingValue(0, defaultFreq, 0.707, "LSC")
      } else if (key.startsWith("Highshelf") || key === "Highshelf") {
        const defaultFreq = defaultDSP[key]?.Frequency?.Current || 10000
        eqSettings[key] = createEqSettingValue(0, defaultFreq, 0.707, "HSC")
      } else if (key.startsWith("Peaking")) {
        const defaultFreq = defaultDSP[key]?.Frequency?.Current || 1000
        eqSettings[key] = createEqSettingValue(0, defaultFreq, 0.707, "PK")
      }
    }
  })

  console.debug("Final EQ settings:", eqSettings)
  return eqSettings
}

const getDefaultFilterLine = (index) => {
  if (index === 0) return `Filter 1: OFF LSC Fc 100 Hz Gain 0 dB Q 0.707`
  if (index === 9) return `Filter 10: OFF HSC Fc 10000 Hz Gain 0 dB Q 0.707`
  return `Filter ${index + 1}: OFF PK Fc 1000 Hz Gain 0 dB Q 0.707`
}

/**
 * Reorder EQ settings to match the order of the filters
 *
 * @param eqSettings R
 * @param has12Bands Whether the device supports 12 bands (true) or 10 bands (false)
 * @returns
 */
const reorderEqSettings = (eqSettings, has12Bands = true) => {
  const order = getFilterOrder(has12Bands)

  const reorderedSettings = {}
  order.forEach((key) => {
    if (eqSettings[key]) {
      reorderedSettings[key] = eqSettings[key]
    }
  })

  // Include Preamp if it exists
  if (eqSettings.Preamp) {
    reorderedSettings.Preamp = eqSettings.Preamp
  }

  return reorderedSettings
}

export {
  eqSettingsToText,
  eqSettingsToURLParam,
  reorderEqSettings,
  textToEqSettings,
  urlParamToEqSettings,
  validateEqSettings,
  validateTextSettings,
  validateUrlParamSettings,
}
