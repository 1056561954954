import Label from "@/components/forms/Label"
import NumberInput from "@/components/forms/NumberInput"
import RadioGroup from "@/components/forms/RadioGroup"
import { useState, useEffect } from "react"

/**
 * PreampGainSettings Component
 * 
 * Manages preamp gain settings with two modes:
 * - AUTO: Firmware automatically calculates gain to prevent digital clipping
 * - MANUAL: User-controlled gain settings
 * 
 * Auto Save Behavior:
 * Changes to preamp settings follow the global auto-save configuration:
 * - "immediate": Changes are instantly applied and saved to device flash
 * - "disabled": Changes are only auditioned until manually saved
 * - "10s"/"1min": Changes are auditioned immediately and saved after delay
 * 
 * Note: When in AUTO mode, gain values are calculated automatically and
 * are not affected by auto-save settings. Only changes to the mode itself
 * (switching between AUTO/MANUAL) follow auto-save behavior.
 */
type PreampGainSettingsProps = {
  eqSettings: any
  mode: string
  onEqChange: (changes: any[]) => void
  peakGain?: number // Add peak gain prop
  maxFilterGain?: number // Add max filter gain prop
  showDebugInfo?: boolean // Optional flag to control visibility of debug info
}

const PreampGainSettings = ({ 
  eqSettings, 
  onEqChange, 
  mode, 
  peakGain = 0, 
  maxFilterGain = 0,
  showDebugInfo = true // Debug info is visible by default
}) => {
  const [preampMode, setPreampMode] = useState<string>()
  const [preampGain, setPreampGain] = useState<number>(0) // Initialize with 0 to avoid undefined

  /**
   * On settings change
   *
   * When parent settings change, update preamp settings
   */
  useEffect(() => {
    if (eqSettings) {
      setPreampMode(eqSettings["Preamp"]["Mode"]["Current"])
      setPreampGain(parseFloat(eqSettings["Preamp"]["Gain"]["Current"]))
    }
  }, [eqSettings, mode])

  /**
   * Update
   *
   * @param {*} domain
   * @param {*} value
   * @returns
   */
  const update = async (domain, value) => {
    const success = await onEqChange([["Preamp", domain, value]])
    return success
  }

  // Calculate recommended preamp gain based on peak response gain, not max filter gain
  const recommendedGain = -(2 + Math.max(0, peakGain))
  const recommendedGainFormatted = recommendedGain.toFixed(1)
  
  // Calculate auto gain based on max filter gain
  const autoGain = -(2 + Math.max(0, maxFilterGain))
  const autoGainFormatted = autoGain.toFixed(1)
  
  // Determine if current preamp gain is adequate - simply check if it's less than or equal to the recommended value
  const isPreampGainAdequate = preampGain <= recommendedGain;
  
  // Determine if auto gain would be inadequate (when peak response is higher than max filter gain)
  // Use a small tolerance (0.05 dB) to prevent rounding issues from triggering the warning incorrectly
  const TOLERANCE = 0.05; // 0.05 dB tolerance for floating point comparisons
  const shouldSetManually = recommendedGain < (autoGain - TOLERANCE);

  return (
    <div className="space-y-4">
      <div className="flex items-center gap-x-5">
        <Label
          text="Preamp Gain"
          info={
            <div className="max-w-md space-y-1">
              <p>
                Equalization is a 3-step process: Apply negative preamp gain, apply DSP filter(s), and
                compensate volume.
              </p>
              <p>
                In <strong>AUTO</strong> mode, preamp gain is automatically calculated to avoid
                digital clipping. Element IV uses a fast algorithm to avoid nearly all chances 
                of clipped audio, but does not calculate overlap of adjacent filters. You may 
                hear distortion like wind in one or both channels when preamp gain is inadequate
                for your filter settings.
              </p>
              <p>
                Use <strong>MANUAL</strong> mode when <i>Peak Frequency Response Gain</i> is larger
                than <i>Max Filter Gain</i>. We recommend at least 2 dB of headroom for all DSP 
                filters to avoid overflow. That is:
              </p>
              <p>
                Recommended Preamp Gain = -(2 + Peak Frequency Response Gain)
              </p>
            </div>
          }
        />
        <div className="flex items-center gap-x-3">
          <RadioGroup
            className="" // Add empty className to fix linter error
            selected={preampMode}
            items={eqSettings["Preamp"]["Mode"]["Elements"].map((settingsValue) => {
              return {
                label: settingsValue,
                value: settingsValue,
              }
            })}
            onValueChange={(value) => update("Mode", value)}
          />
          <NumberInput
            value={preampGain}
            min={eqSettings["Preamp"]["Gain"]["Min"]}
            max={eqSettings["Preamp"]["Gain"]["Max"]}
            tabIndex={1} // Add tabIndex to fix linter error
            onDebouncedChange={(value) => update("Gain", value)}
            disabled={preampMode === "AUTO"}
          />
        </div>
      </div>
      
      {showDebugInfo && (
        <div className="mt-2 rounded-md bg-[#121212] border border-[#333] p-4 font-mono text-sm shadow-inner">
          <div className="grid grid-cols-2 gap-x-6 gap-y-2">
            <div className="text-[#888]">Peak Freq. Response Gain:</div>
            <div className={`font-medium ${peakGain > 0 ? 'text-yellow-400' : 'text-blue-400'}`}>
              {peakGain.toFixed(1)} dB
            </div>
            
            <div className="text-[#888]">Max Filter Gain:</div>
            <div className={`font-medium ${maxFilterGain > 0 ? 'text-yellow-400' : 'text-blue-400'}`}>
              {maxFilterGain.toFixed(1)} dB
            </div>
            
            {/* Faint horizontal separator */}
            <div className="col-span-2 my-2 border-t border-[#333]"></div>
            
            <div className="text-[#888]">Auto Preamp Gain:</div>
            <div className={`font-medium ${autoGain > 0 ? 'text-yellow-400' : 'text-blue-400'}`}>
              {autoGainFormatted} dB
            </div>
            
            <div className="text-[#888]">Recommended Preamp:</div>
            <div className={`font-medium ${isPreampGainAdequate ? 'text-green-400' : 'text-red-400'}`}>
              {recommendedGainFormatted} dB {shouldSetManually && '(Set Manually!)'}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default PreampGainSettings
