import Layout from "@/components/layouts/DefaultLayout"

const ChangelogPage = () => {
  return (
    <Layout>
      <div className="flex w-full flex-1 pt-32">
        <div className="container mx-auto max-w-4xl px-4">
          <h1 className="mb-8 text-2xl font-bold">Core Changelog</h1>

          <div className="space-y-8">
          <div>
              <h2 className="mb-4 text-xl font-semibold text-green">
                Build: Current
                <div className="text-sm font-medium text-gray-400">March 20, 2025</div>
              </h2>
              <div className="ml-4 space-y-2 text-gray-300">
                <ul className="ml-4 list-disc space-y-2">
                  <li>Nodes are now responsive, sized based on DPI Scaling and resolution</li>
                </ul>
              </div>
            </div>

            <div>
              <h2 className="mb-4 text-xl font-semibold text-green">
                Build:  68e51c6
                <div className="text-sm font-medium text-gray-400">March 14, 2025</div>
              </h2>
              <div className="ml-4 space-y-2 text-gray-300">
                <ul className="ml-4 list-disc space-y-2">
                  <li>Support for 10-band and 12-band firmwares</li>
                  <li>Enforce decimal precision for gain values in EQ chart</li>
                  <li>Added detailed calculations to Preamp Gain tab</li>
                  <li>EQ Sidebar is now collapsible for more EQ screen real estate</li>
                </ul>
              </div>
            </div>

            <div>
              <h2 className="mb-4 text-xl font-semibold text-green">
                Build: a476807
                <div className="text-sm font-medium text-gray-400">Feb 25, 2025</div>
              </h2>
              <div className="ml-4 space-y-2 text-gray-300">
                <ul className="ml-4 list-disc space-y-2">
                  <li>Gradient beneath frequency curve</li>
                  <li>Node dragging: Re-styled and animated with crosshair</li>
                  <li>Removed Q-Sliders: use mouse wheel to adjust Q</li>
                  <li>Improved appearance of freq. axis labels</li>
                  <li>More gridlines</li>
                </ul>
              </div>
            </div>

            <div>
              <h2 className="mb-4 text-xl font-semibold text-green">
                Build: 6a973dc
                <div className="text-sm font-medium text-gray-400">Feb 17, 2025</div>
              </h2>
              <div className="ml-4 space-y-2 text-gray-300">
                <ul className="ml-4 list-disc space-y-2">
                  <li>"Output Mode" now toggles EL4 output in real-time</li>
                  <li>Added "Input Mode" toggle, also in real-time</li>
                </ul>
              </div>
            </div>

            <div>
              <h2 className="mb-4 text-xl font-semibold text-green">
                Build: 6d33f42
                <div className="text-sm font-medium text-gray-400">Feb 14, 2025</div>
              </h2>
              <div className="ml-4 space-y-2 text-gray-300">
                <ul className="ml-4 list-disc space-y-2">
                  <li>Removed unecessary numbers from Peaking filter names</li>
                  <li>Added Console</li>
                  <li>Updated FAQ</li>
                </ul>
              </div>
            </div>

            <div>
              <h2 className="mb-4 text-xl font-semibold text-green">
                Build: 1f3cecd
                <div className="text-sm font-medium text-gray-400">Feb 10, 2025</div>
              </h2>
              <div className="ml-4 space-y-2 text-gray-300">
                <ul className="ml-4 list-disc space-y-2">
                  <li>Added FAQ link in footer</li>
                  <li>Display Error messages for 10s, and added more explicit help text</li>
                  <li>Added Connection Details & Disconnect button to "Connect"</li>
                </ul>
              </div>
            </div>

            <div>
              <h2 className="mb-4 text-xl font-semibold text-green">
                Build: e96d91f
                <div className="text-sm font-medium text-gray-400">Feb 6, 2025</div>
              </h2>
              <div className="ml-4 space-y-2 text-gray-300">
                <ul className="ml-4 list-disc space-y-2">
                  <li>Fixed typo on Device Settings page</li>
                  <li>Added guidance for installing firmware updates on Linux</li>
                </ul>
              </div>
            </div>

            <div>
              <h2 className="mb-4 text-xl font-semibold text-green">
                Build: eecb6f4
                <div className="text-sm font-medium text-gray-400">Feb 1, 2025</div>
              </h2>
              <div className="ml-4 space-y-2 text-gray-300">
                <ul className="ml-4 list-disc space-y-2">
                <li>Cleaned up messages in firmware update process</li>
                  <li>Graceful error handling when API server is down</li>
                  <li>Set default optical mode for pending f/w release</li>
                  <li>Removed "No Preset Loaded" message</li>
                  <li>Improved star icons in presets</li>
                  <li>Tabular EQ: Replaced toggle with tippy arrow</li>
                </ul>
              </div>
            </div>

            <div>
              <h2 className="mb-4 text-xl font-semibold text-green">
                Build: 0857cfe
                <div className="text-sm font-medium text-gray-400">January 22, 2025</div>
              </h2>
              <div className="ml-4 space-y-2 text-gray-300">
                <ul className="ml-4 list-disc space-y-2">
                  <li>Made Auto Save dropdown setting persistent when logged in</li>
                </ul>
              </div>
            </div>

            <div>
              <h2 className="mb-4 text-xl font-semibold text-green">
                Build: c76c25d
                <div className="text-sm font-medium text-gray-400">January 8, 2025</div>
              </h2>
              <div className="ml-4 space-y-2 text-gray-300">
                <ul className="ml-4 list-disc space-y-2">
                  <li>Success messages replace previous messages to eliminate stacked alerts</li>
                  <li>Removed Band Limits in x-axis and removed toggle based on feedback</li>
                  <li>Added ability to adjust Q of any filter by scrolling the mouse wheel</li>
                  <li>Device Settings: Show defaults and improved sliders</li>
                  <li>
                    Auto Save: Replaced toggle with dropdown menu and more choices. Improved alerts
                    and tooltips.
                  </li>
                </ul>
              </div>
            </div>

            <div>
              <h2 className="mb-4 text-xl font-semibold text-green">
                Build: c6194580
                <div className="text-sm font-medium text-gray-400">December 20, 2024</div>
              </h2>
              <div className="ml-4 space-y-2 text-gray-300">
                <ul className="ml-4 list-disc space-y-2">
                  <li>Added support for DSP over Optical toggle</li>
                  <li>Added description for LED Mode: OFF</li>
                </ul>
              </div>
            </div>

            <div>
              <h2 className="mb-4 text-xl font-semibold text-green">
                Build: 8b2ca68
                <div className="text-sm font-medium text-gray-400">December 19, 2024</div>
              </h2>
              <div className="ml-4 space-y-2 text-gray-300">
                <ul className="ml-4 list-disc space-y-2">
                  <li>
                    Loading/importing presets now updates the device regardless of auto-save toggle
                  </li>
                  <li>Added tooltips to Auto Save and Band Limits</li>
                </ul>
              </div>
            </div>

            <div>
              <h2 className="mb-4 text-xl font-semibold text-green">
                Build: a2a23e7
                <div className="text-sm font-medium text-gray-400">December 13, 2024</div>
              </h2>
              <div className="ml-4 space-y-2 text-gray-300">
                <h3 className="mb-2 font-semibold">Improved toggle states</h3>
                <ul className="ml-4 list-disc space-y-2">
                  <li>
                    Feature: Re-enabling a disabled band restores to last gain for easier
                    evaluation(thanks Golden!)
                  </li>
                  <li>
                    Feature: Added "Band Limits" toggle: Allows unrestricted frequency placement
                  </li>
                  <li>
                    Clarified desktop browser requirements (1024px wide and in landscape
                    orientation)
                  </li>
                  <li>Fixed: Resizing window would reset bands upon next change</li>
                </ul>
              </div>
            </div>

            <div>
              <h2 className="mb-4 text-xl font-semibold text-green">
                Build: 07e0e6b2
                <div className="text-sm font-medium text-gray-400">December 3, 2024</div>
              </h2>
              <div className="ml-4 space-y-2 text-gray-300">
                <h3 className="mb-2 font-semibold">UI and Filter Type Improvements</h3>
                <ul className="ml-4 list-disc space-y-2">
                  <li>Radio button states now update immediately without page reload</li>
                  <li>Added support for both LSC/LS and HSC/HS filter type abbreviations</li>
                  <li>Updated validation and conversion logic for consistent handling</li>
                  <li>Improved filter type mapping in text imports</li>
                  <li>Added version display to footer</li>
                </ul>
              </div>
            </div>

            <div>
              <h2 className="mb-4 text-xl font-semibold text-green">
                Build: f65b6e21
                <div className="text-sm font-medium text-gray-400">November 30, 2024</div>
              </h2>
              <div className="ml-4 space-y-2 text-gray-300">
                <h3 className="mb-2 font-semibold">EQ Table Improvements</h3>
                <ul className="ml-4 list-disc space-y-2">
                  <li>Added proper step values to Gain field</li>
                  <li>Improved number input UX and precision</li>
                  <li>Setup version checking and notifications</li>
                </ul>
              </div>
            </div>

            <div>
              <h2 className="mb-4 text-xl font-semibold text-green">
                Build: a8461122
                <div className="text-sm font-medium text-gray-400">November 29, 2024</div>
              </h2>
              <div className="ml-4 space-y-2 text-gray-300">
                <h3 className="mb-2 font-semibold">Improved Preset Export Precision</h3>
                <ul className="ml-4 list-disc space-y-2">
                  <li>Frequency: rounded to integers (e.g., "100 Hz")</li>
                  <li>Gain: 1 decimal place (e.g., "-3.5 dB")</li>
                  <li>Q values: 3 decimal places (e.g., "0.707")</li>
                </ul>
              </div>
            </div>

            <div>
              <h2 className="mb-4 text-xl font-semibold text-green">
                Build: bc8a2e91
                <div className="text-sm font-medium text-gray-400">November 28, 2024</div>
              </h2>
              <div className="ml-4 space-y-2 text-gray-300">
                <h3 className="mb-2 font-semibold">Fixed Import Parsing Issues</h3>
                <ul className="ml-4 list-disc space-y-2">
                  <li>Corrected Peaking filter counter in URL imports</li>
                  <li>Fixed filter position mapping</li>
                  <li>Added support for AutoEQ and Squiglink presets</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ChangelogPage
