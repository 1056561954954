import { useState } from "react"
import { Tabs as NextUITabs, Tab } from "@nextui-org/react"
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/24/outline"
import cx from "classnames"

const Tabs = ({ children, expanded = true, ...props }) => {
  const [showPanel, setShowPanel] = useState(expanded)
  return (
    <div className="relative">
      <NextUITabs
        aria-label="Options"
        color="primary"
        variant="underlined"
        classNames={{
          base: "flex",
          tabList: "flex-row-reverse justify-end w-full p-0 border-0 gap-0 -mb-px",
          cursor: "hidden",
          tab: [
            "w-auto min-w-40 h-10 px-7 -ml-3 last:ml-0 transition-colors duration-100",
            cx(
              "type-heading-base font-bold bg-jaguar border border-tundora rounded-tl-lg rounded-tr-lg",
              { "rounded-bl-lg rounded-br-lg": !showPanel }
            ),
            cx(
              "data-[selected=true]:bg-montana data-[selected=true]:border-l-darker-red data-[selected=true]:border-r-darker-red data-[selected=true]:border-t-darker-red data-[selected=true]:z-20",
              showPanel
                ? "data-[selected=true]:border-b-montana"
                : "data-[selected=true]:border-b-darker-red"
            ),
            "data-[hover=true]:opacity-100 data-[hover=true]:bg-mine-shaft",
            "data-[disabled=true]:opacity-100",
          ],
          tabContent: "group-data-[selected=true]:text-white group-data-[disabled=true]:opacity-20",
          panel: cx("border border-tundora min-h-64 py-6 pl-7 pr-10 overflow-auto", {
            hidden: !showPanel,
          }),
        }}
        {...props}
      >
        {[...children].reverse()}
      </NextUITabs>
      <button
        className="clickable absolute right-0 top-0 flex h-10 w-10 items-center justify-center rounded-tr-lg bg-mine-shaft text-white hover:bg-tundora transition-colors"
        onClick={() => setShowPanel(!showPanel)}
        aria-label={showPanel ? "Hide panel" : "Show panel"}
        type="button"
      >
        {showPanel ? (
          <ChevronDownIcon className="h-5 w-5" />
        ) : (
          <ChevronUpIcon className="h-5 w-5" />
        )}
      </button>
    </div>
  )
}

export { Tabs, Tab }
