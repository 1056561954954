import EqualizerCanvas from "@/components/EqualizerCanvas"
import EqualizerTable from "@/components/EqualizerTable"
import EqualiserSidebar from "@/components/interface/EqualiserSidebar"
import PresetHeader from "@/components/interface/PresetHeader"
import EqualizerLayout from "@/components/layouts/EqualizerLayout"
import ConfirmationModal from "@/components/modals/ConfirmationModal"
import ImportPresetModal from "@/components/modals/ImportPresetModal"
import NewPresetModal from "@/components/modals/NewPresetModal"
import PreampGainSettings from "@/components/PreampGainSettings"
import { Tabs, Tab } from "@/components/Tabs"
import { ReactComponent as InfoIcon } from "@/icons/info-icon.svg"
import { DEVICE_CONNECTED, MODE_HEADPHONES, MODE_RCA } from "@/constants"
import { useSerialDevice } from "@/contexts/serial-device"
import { Preset, usePresets } from "@/hooks/presets"
import { DEFAULT_SETTINGS } from "@/settings"
import { applySettingsUpdate, buildSettingsUpdate, toSettingsUpdate } from "@/utils/device"
import {
  eqSettingsToText,
  eqSettingsToURLParam,
  reorderEqSettings,
  textToEqSettings,
  urlParamToEqSettings,
  validateEqSettings,
} from "@/utils/equaliser"
import { downloadBase64, downloadBlob } from "@/utils/file"
import { useDisclosure } from "@nextui-org/react"
import JSZip from "jszip"
import { useEffect, useRef, useState, useCallback } from "react"
import { useLocation } from "react-router-dom"
import { toast } from "react-toastify"
import slugify from "slugify"
import Tooltip from "@/components/Tooltip"
import { useUserSettings } from "@/hooks/user-settings"

/**
 * Interface representing a serial device connection for DSP settings management.
 * This interface provides methods for interacting with a connected DSP device,
 * allowing for real-time settings updates and persistence.
 * 
 * @interface SerialDevice
 * @property {string} status - Current connection status of the device
 * @method getSettings - Retrieves current settings from the device
 * @method updateSettings - Applies settings and saves to device flash memory
 * @method auditionSettings - Applies settings to device without saving to flash
 * @method saveSettings - Saves current settings to device flash memory
 */
interface SerialDevice {
  status: string
  getSettings: () => Promise<any>
  saveSettings: () => Promise<any>
  updateSettings: (settings: any) => Promise<any>
  auditionSettings: (settings: any) => Promise<any>
}

const DEVICE_UPDATE_TOAST_ID = "device-update-toast"

const EqualizerPage = () => {
  const location = useLocation()
  const { 
    status: deviceStatus,
    getSettings,
    saveSettings,
    updateSettings,
    auditionSettings,
    dspSettings,
    setDspSettings,
    cachedGains,
    setCachedGains,
    deviceHas12Bands
  } = useSerialDevice()
  const { presets, activePreset, setActivePreset, deletePreset, createPreset, updateActivePreset } =
    usePresets()
  const { autoSaveMode, isLoading, updateAutoSaveMode } = useUserSettings()

  const sharedEqSettings = useRef(null)
  const deviceReady = useRef(false)
  const [mode, setMode] = useState(MODE_HEADPHONES)
  const [eqChanged, setEqChanged] = useState(false)
  const [deviceSettings, setDeviceSettings] = useState(null)
  const [activePresetOutdated, setActivePresetOutdated] = useState(false)
  const [selectedTab, setSelectedTab] = useState("tabularEQ")
  const autoSaveTimer = useRef<NodeJS.Timeout | null>(null)
  const [useBandLimits, setUseBandLimits] = useState(false)
  
  // Add state for peak gain and max filter gain
  const [peakGain, setPeakGain] = useState(0)
  const [maxFilterGain, setMaxFilterGain] = useState(0)

  const {
    isOpen: newPresetModalOpen,
    onClose: closeNewPresetModal,
    onOpen: openNewPresetModal,
  } = useDisclosure()

  const {
    isOpen: importPresetModalOpen,
    onOpen: openImportPresetModal,
    onOpenChange: onImportPresetModalChange,
  } = useDisclosure()

  const {
    isOpen: shareModalOpen,
    onClose: closeShareModal,
    onOpen: openShareModal,
  } = useDisclosure()

  const applyEqSettings = async (newEqSettings) => {
    // Update local state
    setDspSettings((prevDspSettings) => {
      prevDspSettings[mode] = structuredClone(reorderEqSettings(newEqSettings, deviceHas12Bands))
      return structuredClone(prevDspSettings)
    })

    // Send to device immediately
    if (deviceStatus === DEVICE_CONNECTED) {
      const deviceSettings = {
        Configuration: { DSP: { [mode]: toSettingsUpdate(newEqSettings) } },
      }
      try {
        await updateSettings(deviceSettings)
      } catch (error) {
        console.error(error)
        toast.error("Error updating device")
      }
    }
  }

  /**
   * When the EQ settings are changed either via the interactive canvas or the table.
   *
   * @param {*} updates
   */
  const onEqChange = async (updateArray) => {
    setEqChanged(true)
    const settingUpdates = updateArray.reduce((obj, [band, domain, value]) => {
      return buildSettingsUpdate(obj, [band, domain, "Current"], value)
    }, {})

    setDspSettings((prevSettings) => {
      const prevModeSettings = prevSettings[mode]
      const newEqSettings = {
        ...prevModeSettings,
        ...applySettingsUpdate(prevModeSettings, settingUpdates),
      }
      return {
        ...prevSettings,
        [mode]: newEqSettings,
      }
    })
  }

  /**
   * When the user clicks "Save to Device" button after a change, commit the settings
   */
  const onEqSave = async () => {
    setEqChanged(false)
    try {
      await saveSettings()
    } catch (error) {
      console.error(error)
      toast.error("Error saving to device")
    }
  }

  /**
   * When a preset is selected, load the preset's eq settings
   *
   * @param preset
   */
  const onPresetSelected = async (preset) => {
    setActivePreset(preset)
    setEqChanged(false)
    await applyEqSettings(preset.config)
  }

  /**
   * When "Clear ALl EQ bands" is clicked, reset the eq settings
   *
   */
  const onDisableEqClicked = async () => {
    setActivePreset(null)
    setEqChanged(false)
    const defaultEqSettings = structuredClone(DEFAULT_SETTINGS["Configuration"]["DSP"][mode])
    
    // For 10-band devices, adjust filter names to match the expected format
    if (!deviceHas12Bands) {
      // Create "Lowshelf" from "Lowshelf 1" if it doesn't exist
      if (!defaultEqSettings["Lowshelf"] && defaultEqSettings["Lowshelf 1"]) {
        defaultEqSettings["Lowshelf"] = structuredClone(defaultEqSettings["Lowshelf 1"])
      }
      
      // Create "Highshelf" from "Highshelf 1" if it doesn't exist
      if (!defaultEqSettings["Highshelf"] && defaultEqSettings["Highshelf 1"]) {
        defaultEqSettings["Highshelf"] = structuredClone(defaultEqSettings["Highshelf 1"])
      }
    }
    
    await applyEqSettings(defaultEqSettings)
  }

  /**
   * When the user confirms they want to apply shared EQ settings from a URL
   */
  const onConfirmShareClicked = async () => {
    try {
      setActivePreset(null)
      await applyEqSettings(sharedEqSettings.current)
      toast.success("Shared settings applied successfully")
    } catch (error) {
      console.error("Error applying shared settings:", error)
      toast.error("Failed to apply shared settings")
    }
    closeShareModal()
  }

  /**
   * When a new preset is imported by dropping on the overlay, load it.
   *
   */
  const onImportPresetConfirmed = async (file) => {
    setActivePreset(null)
    setEqChanged(false)
    const importedEqSettings = textToEqSettings(file.content, deviceHas12Bands)
    await applyEqSettings(importedEqSettings)
  }

  /**
   * When the "save" button is clicked, update the current preset
   *
   * NOTE: This should only be callable when the EQ values have changed while
   * a preset has already been selected
   */
  const onSavePresetClicked = async (preset) => {
    preset.config = dspSettings[mode]
    try {
      const updatedPreset = await updateActivePreset(preset)
      if (!updatedPreset) {
        throw new Error("Error updating preset")
      } else {
        toast.success("Updated preset")
      }
    } catch (error) {
      console.error(error)
      toast.error("Error updating preset")
    }
  }

  /**
   * When a "favourite" star is clicked, toggle the preset's favourite status
   *
   * @param preset
   */
  const onFavouritePresetClicked = async (preset: Preset) => {
    preset.is_favourite = !preset.is_favourite
    try {
      const updatedPreset = await updateActivePreset(preset)
      if (!updatedPreset) {
        throw new Error("Error favouriting preset")
      }
    } catch (error) {
      console.error(error)
      toast.error("Error favouriting preset")
    }
  }

  /**
   * When the "delete" trash icon is pressed, delete the current preset
   *
   * @param preset
   */
  const onDeletePresetClicked = async (preset) => {
    const confirmation = await window.confirm("Are you sure you want to delete this preset")
    if (confirmation) {
      try {
        const deleted = await deletePreset(preset)
        if (!deleted) {
          throw new Error("Unknown error")
        } else {
          toast.success("Preset deleted")
        }
      } catch (error) {
        console.error(error)
        toast.error("Error deleting preset")
      }
    }
  }

  /**
   * When the "export" button is clicked, export the currently loaded EQ settings
   * as a text file in Squiglink format
   *
   */
  const onExportClicked = async () => {
    const errors = validateEqSettings(dspSettings[mode])
    if (errors.length > 0) {
      console.debug(errors)
      toast.error("Error exporting current EQ settings")
      return
    }
    const textData = eqSettingsToText(dspSettings[mode], deviceHas12Bands)
    const fileName = activePreset
      ? `${slugify(activePreset.name, {
          lower: true,
          strict: true,
        })}-preset-export.txt`
      : "jds-preset-export.txt"
    const blob = new Blob([textData], { type: "text/plain" })
    downloadBlob(blob, "text/plain", fileName)
    toast.success("Current EQ settings exported")
  }

  /**
   * When the "export all" button is pressed, convert all currently available
   * presets and convert them to Squiglink-compatible text files. Then zip
   * them and prompt a download.
   *
   */
  const onExportAllClicked = async () => {
    const zip = new JSZip()

    presets
      .filter((preset) => preset.mode.toLowerCase() === mode.toLowerCase())
      .forEach((preset, i) => {
        const errors = validateEqSettings(preset.config)
        if (errors.length > 0) {
          toast.error(`Error adding preset '${preset.name}' to export`)
          return
        }
        const textData = eqSettingsToText(preset.config, deviceHas12Bands)
        const fileName = `jds-presets/${slugify(preset.name, {
          lower: true,
          strict: true,
        })}-preset-export-${i + 1}.txt`
        zip.file(fileName, textData)
      })

    const base64 = await zip.generateAsync({ type: "base64" })
    downloadBase64(base64, "application/zip", `jds-presets.zip`)
    toast.success("All presets exported")
  }

  /**
   * When either RCA or Headphone sidebar button is clicked, change the mode.
   *
   */
  const onModeChanged = async (mode) => {
    setMode(mode)
    setActivePreset(null)

    // Convert our mode format back to device format (e.g. "HEADPHONES" -> "Headphone")
    const deviceModeMap = {
      [MODE_HEADPHONES]: "Headphone",
      [MODE_RCA]: "RCA"
    }

    // Update the device with the new mode
    if (deviceStatus === DEVICE_CONNECTED) {
      try {
        await updateSettings({
          Product: "JDS Labs Element IV",
          "Format Output": true,
          Action: "Update",
          Configuration: {
            General: {
              "Output Mode": deviceModeMap[mode]  // Send the string value directly
            }
          }
        })
      } catch (error) {
        console.error("Error updating device mode:", error)
        toast.error("Error updating device mode")
      }
    }
  }

  /**
   * When the "Save as new preset" button is clicked in the header, open the overlay.
   *
   */
  const onSaveNewPresetClicked = async () => {
    openNewPresetModal()
  }

  /**
   * When the sidebar "Import Preset" button is clicked, open the overlay.
   *
   */
  const onImportPresetClicked = async () => {
    openImportPresetModal()
  }

  /**
   * When the "get link" button is clicked, encode the current EQ settings into
   * the URL and save it to the clipboard
   *
   */
  const onGetLinkClicked = useCallback(() => {
    try {
      // Generate URL param string based on current settings and device capabilities
      const encodedSettings = eqSettingsToURLParam(dspSettings[mode], deviceHas12Bands);
      
      // Construct the full shareable URL
      const url = `${window.location.origin}${window.location.pathname}?share=${encodedSettings}`;
      
      // Copy to clipboard
      navigator.clipboard.writeText(url);
      toast.success("Link copied to clipboard");
    } catch (error) {
      console.error("Error generating shareable link:", error);
      toast.error("Failed to generate shareable link");
    }
  }, [dspSettings, mode, deviceHas12Bands]);

  /**
   * When the "save" button is clicked in the header, update the current preset.
   *
   */
  const onConfirmNewPresetClicked = async (values) => {
    try {
      const newPreset = await createPreset({
        name: values.name,
        mode: mode.toLowerCase(),
        config: dspSettings[mode],
      })
      if (!newPreset) {
        throw new Error("Error creating preset")
      } else {
        closeNewPresetModal()
        toast.success("Created preset")
      }
    } catch (error) {
      console.error(error)
      toast.error("Error creating preset")
    }
  }

  /**
   * When the device is connected, load the settings
   *
   */
  useEffect(() => {
    if (deviceStatus === DEVICE_CONNECTED) {
      ;(async () => {
        try {
          const deviceSettings = await getSettings()
          setDspSettings(deviceSettings["Configuration"]["DSP"])
          setDeviceSettings(deviceSettings)
          
          // Set initial mode based on device's current output mode
          const currentOutputMode = deviceSettings["Configuration"]?.["General"]?.["Output Mode"]?.["Current"]
          if (!currentOutputMode) {
            console.error("No output mode found in device settings:", deviceSettings)
            // Fall back to default mode from settings
            setMode(DEFAULT_SETTINGS.Configuration.General["Output Mode"].Current === "Headphone" ? MODE_HEADPHONES : MODE_RCA)
            return
          }

          // Convert device mode format to our constant format (e.g. "Headphone" -> "HEADPHONES")
          const modeMap = {
            "Headphone": MODE_HEADPHONES,
            "RCA": MODE_RCA
          }
          
          const mappedMode = modeMap[currentOutputMode]
          if (!mappedMode) {
            console.error(`Unknown output mode '${currentOutputMode}' received from device`)
            // Fall back to default mode
            setMode(MODE_HEADPHONES)
            return
          }

          setMode(mappedMode)
        } catch (error) {
          console.error("Error getting device settings:", error)
          toast.error("Error getting device settings")
          // Fall back to default mode
          setMode(MODE_HEADPHONES)
        }
      })()
    }
  }, [deviceStatus])

  /**
   * When local settings change, send the changes to the device
   */
  useEffect(() => {
    if (deviceStatus === DEVICE_CONNECTED && dspSettings && deviceSettings) {
      if (deviceReady.current === true) {
        const updateDeviceSettings = async () => {
          const newDeviceSettings = {
            Configuration: { DSP: { [mode]: toSettingsUpdate(dspSettings[mode]) } },
          }
          try {
            switch (autoSaveMode) {
              case "immediate":
                // Immediately apply and save
                await updateSettings(newDeviceSettings)
                break
              case "disabled":
                // Only audition changes, user must manually save
                await auditionSettings(newDeviceSettings)
                break
              case "10s":
              case "1min":
                // Clear any existing timer
                if (autoSaveTimer.current) {
                  clearTimeout(autoSaveTimer.current)
                }

                // First, audition the changes immediately
                await auditionSettings(newDeviceSettings)

                // Then set up the delayed save
                const delay = autoSaveMode === "10s" ? 10000 : 60000
                autoSaveTimer.current = setTimeout(async () => {
                  try {
                    // First apply settings, then save them
                    await auditionSettings(newDeviceSettings)
                    await saveSettings()
                  } catch (error) {
                    console.error(error)
                    toast.error("Error saving to device")
                  }
                }, delay)
                break
            }
          } catch (error) {
            console.error(error)
            toast.error("Error updating device")
          }
        }

        // Only update device if EQ settings have actually changed
        if (eqChanged) {
          updateDeviceSettings()
        }
      } else {
        deviceReady.current = true
      }
    }

    // Cleanup any pending auto-save timer when unmounting or when settings change
    return () => {
      if (autoSaveTimer.current) {
        clearTimeout(autoSaveTimer.current)
      }
    }
  }, [deviceStatus, dspSettings, deviceSettings, autoSaveMode])

  /**
   * Track when a preset has become outdated due to the user updating the eq
   * settings while a preset is loaded
   *
   */
  useEffect(() => {
    if (activePreset && dspSettings[mode]) {
      setActivePresetOutdated(
        JSON.stringify(dspSettings[mode]) !== JSON.stringify(activePreset.config)
      )
    }
  }, [dspSettings, activePreset])

  /**
   * Track if the page has loaded with a valid "share" URL parameter and load it
   */
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const sharedSettings = queryParams.get("share")

    // Process the shared preset if present in URL
    if (sharedSettings) {
      try {
        console.debug("Processing shared settings with deviceHas12Bands =", deviceHas12Bands)
        // Convert URL param to EQ settings, respecting the device's band structure
        sharedEqSettings.current = urlParamToEqSettings(sharedSettings, deviceHas12Bands)
        // Show the share modal to let the user apply the settings
        openShareModal()
      } catch (error) {
        // Log the error but don't show a toast since we're still going to show the modal
        console.error("Error processing shared settings:", error)
        
        // If we completely failed to parse the settings, then we can't proceed
        // so we'll show the error toast, but only if we didn't open the modal
        if (!sharedEqSettings.current) {
          toast.error("Invalid shared settings")
        }
      }
    }
  }, [location.search, deviceHas12Bands, openShareModal])

  const handleCacheGain = (bandName: string, gain: number) => {
    setCachedGains((prev: Record<string, number>) => {
      const newCache = { ...prev }
      newCache[bandName] = gain
      return newCache
    })
  }

  const handleClearCache = (bandName: string) => {
    setCachedGains((prev: Record<string, number>) => {
      const newCache = { ...prev }
      delete newCache[bandName]
      return newCache
    })
  }

  // Add handlers for peak gain and max filter gain updates
  const handlePeakGainChange = (gain) => {
    setPeakGain(gain)
  }

  const handleMaxFilterGainChange = (gain) => {
    setMaxFilterGain(gain)
  }

  return (
    <>
      <EqualizerLayout
        header={
          <PresetHeader
            activePreset={activePreset}
            activePresetOutdated={activePresetOutdated}
            onDeletePresetClicked={onDeletePresetClicked}
            onExportClicked={onExportClicked}
            onFavouritePresetClicked={onFavouritePresetClicked}
            onSavePresetClicked={onSavePresetClicked}
            onSaveNewPresetClicked={onSaveNewPresetClicked}
            onGetLinkClicked={onGetLinkClicked}
            autoSave={!isLoading ? autoSaveMode : undefined}
            onAutoSaveChange={(value) => {
              updateAutoSaveMode(
                value,
                autoSaveTimer.current,
                async () => {
                  await updateSettings({
                    Configuration: { DSP: { [mode]: toSettingsUpdate(dspSettings[mode]) } },
                  })
                }
              )
            }}
          />
        }
        content={
          <div className="relative h-full w-full">
            {eqChanged && autoSaveMode === "disabled" && deviceStatus === DEVICE_CONNECTED && (
              <button
                onClick={() => {
                  onEqSave()
                }}
                className="absolute right-[5%] top-[12%] z-10 flex cursor-pointer items-center justify-center gap-1 rounded-lg bg-red px-3 py-2 text-xs font-bold text-white"
              >
                <span className="translate-y-px">Save to Device?</span>
                <Tooltip content="You are currently auditioning EQ changes. Be sure to save settings before powering down.">
                  <InfoIcon className="h-3 w-auto text-white" />
                </Tooltip>
              </button>
            )}
            <EqualizerCanvas
              eqSettings={dspSettings[mode]}
              mode={mode}
              onEqChange={onEqChange}
              cachedGains={cachedGains}
              onCacheGain={handleCacheGain}
              onClearCache={handleClearCache}
              useBandLimits={useBandLimits}
              onPeakGainChange={handlePeakGainChange}
              onMaxFilterGainChange={handleMaxFilterGainChange}
            />
          </div>
        }
        footer={
          <Tabs selectedKey={selectedTab} onSelectionChange={setSelectedTab}>
            <Tab key="tabularEQ" title="Tabular EQ">
              <EqualizerTable
                eqSettings={dspSettings[mode]}
                mode={mode}
                onEqChange={onEqChange}
                cachedGains={cachedGains}
                onCacheGain={handleCacheGain}
                onClearCache={handleClearCache}
              />
            </Tab>
            <Tab key="preampGain" title="Preamp Gain">
              <PreampGainSettings
                eqSettings={dspSettings[mode]}
                mode={mode}
                onEqChange={onEqChange}
                peakGain={peakGain}
                maxFilterGain={maxFilterGain}
                showDebugInfo={true}
              />
            </Tab>
          </Tabs>
        }
        sidebar={
          <EqualiserSidebar
            mode={mode}
            presets={presets}
            activePreset={activePreset}
            onModeChanged={onModeChanged}
            onPresetSeleceted={onPresetSelected}
            onImportPresetClicked={onImportPresetClicked}
            onExportAllClicked={onExportAllClicked}
            onDisableEqClicked={onDisableEqClicked}
            deviceSettings={deviceSettings}
          />
        }
      />
      <NewPresetModal
        isOpen={newPresetModalOpen}
        onClose={closeNewPresetModal}
        onConfirm={onConfirmNewPresetClicked}
      />
      <ImportPresetModal
        isOpen={importPresetModalOpen}
        onOpenChange={onImportPresetModalChange}
        onImportPresetConfirmed={onImportPresetConfirmed}
      />
      <ConfirmationModal
        question="Apply Shared EQ Settings?"
        description={
          "Someone has shared equalizer settings with you via this link. Click 'Yes' to apply these settings to your device."
        }
        confirmText="Yes"
        isOpen={shareModalOpen}
        onClose={closeShareModal}
        onConfirm={onConfirmShareClicked}
      />
    </>
  )
}

export default EqualizerPage
