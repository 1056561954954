import Layout from "@/components/layouts/DefaultLayout"
import { useState } from "react"
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline"

const EQLayout = ({ header, content, footer, sidebar }) => {
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false)

  const toggleSidebar = () => {
    setSidebarCollapsed(!sidebarCollapsed)
  }

  return (
    <Layout>
      <div className="flex h-full overflow-x-hidden">
        <div id="eq-main" className={`flex h-full flex-1 flex-col gap-y-6 overflow-y-auto px-14 py-4`}>
          <div id="eq-header" className="w-full flex-shrink-0">
            {header}
          </div>
          <div id="eq-content" className="flex min-h-0 flex-grow flex-col gap-y-7">
            {content}
          </div>
          <div id="eq-footer" className="w-full flex-shrink-0">
            {footer}
          </div>
        </div>
        <div className="relative h-full flex-shrink-0">
          <div 
            className="absolute -left-6 top-1/2 z-10 h-12 w-12 -translate-y-1/2 cursor-pointer"
            onClick={toggleSidebar}
            role="button"
            tabIndex={0}
            aria-label={sidebarCollapsed ? "Expand sidebar" : "Collapse sidebar"}
            onKeyDown={(e) => e.key === 'Enter' && toggleSidebar()}
          >
            <div className="absolute left-1/2 top-1/2 flex h-8 w-8 -translate-x-1/2 -translate-y-1/2 items-center justify-center rounded-full bg-mine-shaft text-white shadow-md hover:bg-tundora transition-colors">
              {sidebarCollapsed ? (
                <ChevronLeftIcon className="h-5 w-5" />
              ) : (
                <ChevronRightIcon className="h-5 w-5" />
              )}
            </div>
          </div>
          <div 
            id="eq-sidebar" 
            className={`h-full overflow-hidden transition-all duration-300 ${
              sidebarCollapsed ? "w-0 opacity-0" : "w-[420px] opacity-100"
            }`}
            style={{ 
              maxWidth: sidebarCollapsed ? '0px' : '420px',
              minWidth: sidebarCollapsed ? '0px' : '420px'
            }}
          >
            <div className="h-full w-[420px] overflow-y-auto">
              {sidebar}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default EQLayout
