import { useDebug } from "@/contexts/debug"
import { ChevronDownIcon, XMarkIcon, CommandLineIcon, ClipboardIcon, ArrowDownTrayIcon, TrashIcon, PaperAirplaneIcon } from "@heroicons/react/24/outline"
import { useEffect, useRef, useState } from "react"
import cx from "classnames"
import { useSerialDevice } from "@/contexts/serial-device"

const JsonKey = ({ children }: { children: React.ReactNode }) => (
  <span className="text-blue-400">{children}</span>
)

const JsonString = ({ children }: { children: React.ReactNode }) => (
  <span className="text-green-400">{children}</span>
)

const JsonNumber = ({ children }: { children: React.ReactNode }) => (
  <span className="text-yellow-400">{children}</span>
)

const JsonBoolean = ({ children }: { children: React.ReactNode }) => (
  <span className="text-purple-400">{children}</span>
)

const JsonPunctuation = ({ children }: { children: React.ReactNode }) => (
  <span className="text-gray-500">{children}</span>
)

const renderJsonValue = (value: any, parentKey?: string): React.ReactNode => {
  if (value === null) return <JsonBoolean>null</JsonBoolean>
  if (typeof value === 'string') return <JsonString>"{value}"</JsonString>
  if (typeof value === 'number') {
    // Special handling for USB IDs to show them in hex
    if (parentKey && (parentKey === 'usbProductId' || parentKey === 'usbVendorId')) {
      return <JsonNumber>0x{value.toString(16).toUpperCase().padStart(4, '0')}</JsonNumber>
    }
    return <JsonNumber>{value}</JsonNumber>
  }
  if (typeof value === 'boolean') return <JsonBoolean>{value.toString()}</JsonBoolean>
  if (Array.isArray(value)) {
    return (
      <>
        <JsonPunctuation>[</JsonPunctuation>
        <div style={{ marginLeft: '1rem' }}>
          {value.map((item, i) => (
            <div key={i}>
              {renderJsonValue(item)}
              {i < value.length - 1 && <JsonPunctuation>,</JsonPunctuation>}
            </div>
          ))}
        </div>
        <JsonPunctuation>]</JsonPunctuation>
      </>
    )
  }
  if (typeof value === 'object') {
    return (
      <>
        <JsonPunctuation>{'{'}</JsonPunctuation>
        <div style={{ marginLeft: '1rem' }}>
          {Object.entries(value).map(([key, val], i, arr) => (
            <div key={key}>
              <JsonKey>"{key}"</JsonKey>
              <JsonPunctuation>: </JsonPunctuation>
              {renderJsonValue(val, key)}
              {i < arr.length - 1 && <JsonPunctuation>,</JsonPunctuation>}
            </div>
          ))}
        </div>
        <JsonPunctuation>{'}'}</JsonPunctuation>
      </>
    )
  }
  return String(value)
}

// Helper function to render JSON values with context about their key
const renderJsonValueWithKey = (value: any, key: string): React.ReactNode => {
  if (typeof value === 'number' && 
      (key === 'usbProductId' || key === 'usbVendorId')) {
    return <JsonNumber>0x{value.toString(16).toUpperCase().padStart(4, '0')}</JsonNumber>
  }
  return renderJsonValue(value)
}

export default function DebugTerminal() {
  const { logs, isTerminalOpen, setTerminalOpen, clearLogs, log } = useDebug()
  const serialDevice = useSerialDevice()
  const scrollRef = useRef<HTMLDivElement>(null)
  const [command, setCommand] = useState("")
  const textareaRef = useRef<HTMLTextAreaElement>(null)

  const formatJSON = (obj: any) => {
    return (
      <div className="font-mono whitespace-pre">
        {renderJsonValue(obj)}
      </div>
    )
  }

  const copyLogs = () => {
    const formattedLogs = logs.map(log => {
      let logText = `[${log.timestamp}] ${log.message}`;
      if (log.data) {
        logText += '\n' + JSON.stringify(log.data, null, 2);
      }
      return logText;
    }).join('\n');
    
    navigator.clipboard.writeText(formattedLogs).then(() => {
      // Optional: You could add a toast notification here
      console.log('Logs copied to clipboard');
    });
  };

  const downloadLogs = () => {
    const logsData = logs.map(log => ({
      timestamp: log.timestamp,
      level: log.level,
      message: log.message,
      data: log.data
    }));

    const blob = new Blob([JSON.stringify(logsData, null, 2)], { type: 'application/json' });
    const url = window.URL.createObjectURL(blob);
    const now = new Date();
    const timestamp = now.toISOString().replace(/[:.]/g, '-');
    const a = document.createElement('a');
    a.href = url;
    a.download = `output-${timestamp}.json`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  const scrollToBottom = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight
    }
  }

  // Auto-scroll to bottom when new logs are added or terminal is opened
  useEffect(() => {
    scrollToBottom()
  }, [logs, isTerminalOpen])

  // Add keyboard shortcut (Ctrl+~) to toggle terminal
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.ctrlKey && e.key === '`') {
        e.preventDefault();
        setTerminalOpen(!isTerminalOpen);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [setTerminalOpen, isTerminalOpen]);

  // Also scroll to bottom after any content updates
  useEffect(() => {
    const observer = new MutationObserver(scrollToBottom)
    
    if (scrollRef.current) {
      observer.observe(scrollRef.current, {
        childList: true,
        subtree: true,
        characterData: true
      })
    }

    return () => observer.disconnect()
  }, [])

  const isSystemMessage = (message: string) => {
    return message.startsWith('Serial:') || 
           message.includes('port') || 
           message.includes('device') ||
           message.includes('connection')
  }

  const handleSubmit = async () => {
    try {
      const jsonCommand = JSON.parse(command);
      
      // Add required fields if not present
      const fullCommand = {
        Product: "JDS Labs Element IV",
        "Format Output": true,
        ...jsonCommand
      };
      
      // Log the outgoing command
      log('debug', 'Sending command:', fullCommand);
      
      try {
        // Send command to device and wait for response
        const response = await serialDevice.updateSettings(fullCommand);
      } catch (error) {
        log('error', 'Device command error:', error.message);
      }
      
      setCommand("");
    } catch (error) {
      log('error', 'Invalid JSON command:', error.message);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && e.shiftKey) {
      e.preventDefault();
      handleSubmit();
    }
  };

  // Automatically adjust textarea height
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = Math.max(96, textareaRef.current.scrollHeight) + 'px';
    }
  }, [command]);

  if (!isTerminalOpen) {
    return (
      <button
        onClick={() => setTerminalOpen(true)}
        className="flex items-center gap-1 px-2 py-1 text-sm text-gray-500 hover:text-white transition-colors"
      >
        <CommandLineIcon className="h-4 w-4" />
        
      </button>
    )
  }

  return (
    <div className="absolute bottom-0 left-0 right-0 h-80 bg-black border-t border-mine-shaft">
      <div className="flex items-center justify-between p-2 border-b border-mine-shaft">
        <div className="flex items-center gap-2">
          <CommandLineIcon className="h-4 w-4 text-gray-500" />
          <h3 className="text-sm font-medium text-white">Debug Console</h3>
        </div>
        <div className="flex items-center gap-2">
          <button
            onClick={clearLogs}
            className="flex items-center gap-1 text-gray-500 hover:text-white transition-colors"
            title="Clear logs"
          >
            <TrashIcon className="h-4 w-4" />
            <span className="text-sm">Clear</span>
          </button>
          <button
            onClick={downloadLogs}
            className="flex items-center gap-1 text-gray-500 hover:text-white transition-colors"
            title="Download logs as JSON"
          >
            <ArrowDownTrayIcon className="h-4 w-4" />
            <span className="text-sm">Download</span>
          </button>
          <button
            onClick={copyLogs}
            className="flex items-center gap-1 text-gray-500 hover:text-white transition-colors"
            title="Copy logs to clipboard"
          >
            <ClipboardIcon className="h-4 w-4" />
            <span className="text-sm">Copy</span>
          </button>
          <button
            onClick={() => setTerminalOpen(false)}
            className="flex items-center gap-1 text-gray-500 hover:text-white transition-colors"
          >
            <ChevronDownIcon className="h-4 w-4" />
            <span className="text-sm">Close</span>
          </button>
        </div>
      </div>
      <div className="flex flex-col h-[calc(100%-2.5rem)]">
        <div ref={scrollRef} className="flex-1 overflow-auto p-2 font-mono text-sm custom-scrollbar">
          {logs.length === 0 ? (
            <div className="text-gray-500 text-center py-4">No logs yet...</div>
          ) : (
            logs.map((log, index) => (
              <div
                key={index}
                className={cx("mb-1")}
              >
                <span className="text-gray-600">[{log.timestamp}]</span>
                <span className={cx("ml-2", {
                  "text-gray-500": log.level === "debug" && !isSystemMessage(log.message),
                  "text-blue-400": log.level === "info",
                  "text-yellow-400": log.level === "warn",
                  "text-red-400": log.level === "error",
                  "text-emerald-400": isSystemMessage(log.message)
                })}>{log.message}</span>
                {log.data && (
                  <div className="ml-4 text-xs whitespace-pre">
                    {formatJSON(log.data)}
                  </div>
                )}
              </div>
            ))
          )}
        </div>
        <div className="relative border-t border-mine-shaft">
          <textarea
            ref={textareaRef}
            value={command}
            onChange={(e) => setCommand(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder="Enter JSON command... (Shift+Enter to submit)"
            className="w-full min-h-[96px] p-2 bg-black text-white font-mono text-sm resize-none focus:outline-none custom-scrollbar"
          />
          <button
            onClick={handleSubmit}
            className="absolute right-2 bottom-2 text-gray-500 hover:text-white transition-colors"
            title="Submit command (Shift+Enter)"
          >
            <PaperAirplaneIcon className="h-5 w-5" />
          </button>
        </div>
      </div>
    </div>
  )
} 