import { useEffect, useRef } from "react"
import { gsap } from "gsap"
import { Draggable } from "gsap/Draggable"

type CanvasSize = {
  areaWidth: number
  areaHeight: number
}

type Band = {
  fc: number
  gain: number
  Q: number
  type: BiquadFilterType
  label: string
  bandLimits: {
    freq: { min: number; max: number }
    gain: { min: number; max: number }
    Q: { min: number; max: number }
  }
  els: {
    draggableEl?: SVGElement
  }
  coords: {
    point?: {
      x: number
      y: number
    }
    limits?: {
      minX: number
      minY: number
      maxX: number
      maxY: number
    }
  }
  props: {
    midSize: number
    innerColor: string
    outerColor: string
    lastGain: number
    scale: number
  }
}

/**
 * Equalizer canvas
 *
 * This is an iteractive equaliser that lets the user's visually
 * configure their equaliser settings.
 *
 * Note that this was initially developed separately to the React frontend app,
 * as a stand-alone canvas-based app. It is therefore not fully developed in
 * the "React way".
 */
const CHART_CONFIG = {
  margin: 70,
  yAxisPadding: 10,
  fontSize: 22,
  pointerSize: 28, // Base node size for 4K reference display
  pointerMidSize: 0.7,
  palette: {
    red: "#FF423E",
    activeFreqStroke: "#80211f",
    background: "#1E1E1E",
    mainGrid: "#3E3E3E",
    secondaryGrid: "#303030",
    secondaryGridText: "#5B5B5B",
    pointerMid: "#415776",
    pointerMidBottom: "#203046",
    pointerMidGrey: "#888888",
    pointerMidGreyBottom: "#666666",
    pointerOuterBlue: "#6d7b90",
    pointerOuterWhite: "#d5d5d5",
    pointerText: "#ffffff",
    limitsRGB: [89, 120, 162],
    backgroundDarkerRGB: [28, 30, 36],
    crosshairX: "#FF423E", // Red for frequency line
    crosshairY: "#4A90E2", // Blue for gain line
  },
  filterTypeSymbols: {
    lowpass: "LP",
    peaking: "PK",
    highpass: "HP",
    lowshelf: "LS",
    highshelf: "HS"
  }
}

const GLOBAL_LIMITS = {
  freq: { min: 20, max: 20000 },
  gain: { min: -30, max: 30 },
  Q: { min: 0.3, max: 7, default: 0.707 },
}

type EqualizerCanvasProps = {
  eqSettings: any
  mode: string
  onEqChange: (changes: any[]) => void
  cachedGains: Record<string, number>
  onCacheGain: (bandLabel: string, gain: number) => void
  onClearCache: (bandLabel: string) => void
  useBandLimits: boolean
  onPeakGainChange?: (peakGain: number) => void
  onMaxFilterGainChange?: (maxFilterGain: number) => void
  nodeScaling?: boolean // Add prop to enable/disable node scaling
  showDebugOverlay?: boolean // Add prop to control debug overlay visibility
}

const EqualizerCanvas = ({
  eqSettings,
  mode,
  onEqChange,
  cachedGains,
  onCacheGain,
  onClearCache,
  useBandLimits,
  onPeakGainChange,
  onMaxFilterGainChange,
  nodeScaling = true, // Default to true
  showDebugOverlay = false, // Default to false (hidden)
}: EqualizerCanvasProps) => {
  const containerRef = useRef(null)
  const canvasRef = useRef<HTMLCanvasElement | null>(null)
  const canvasContext = useRef<CanvasRenderingContext2D | null>(null)
  const canvasSize = useRef<CanvasSize>({
    areaWidth: 0,
    areaHeight: 0,
  })
  const audioContext = useRef<AudioContext | null>()
  const svgRef = useRef<SVGSVGElement | null>(null)
  const frequencyResponse = useRef([])

  const activeBandAreaOpacity = useRef<number>(0)
  const activeBandIdx = useRef<number>(-1)
  const eqBands = useRef<Band[]>([])

  // Add debounce timer ref
  const qAdjustmentTimer = useRef(null)

  const selectedBandIdx = useRef<number>(-1)
  const crosshairOpacity = useRef<number>(1)
  const crosshairProgress = useRef<number>(0) // Add progress ref for animation

  const peakGain = useRef<number>(0) // Add ref to store peak gain value
  const maxFilterGain = useRef<number>(0) // Add ref to store maximum filter gain
  
  // Add a ref to store the node scaling factor based on screen size
  const nodeScaleFactor = useRef<number>(1) // Default to 1
  
  // Track the last scale value to avoid excessive logging
  const lastLoggedScale = useRef<number | null>(null)

  // Add a ref to track whether debug overlay is visible
  const showDebugInfo = useRef<boolean>(showDebugOverlay)

  // Add refs for storing scaling calculation details
  const debugScalingInfo = useRef<{
    referenceWidth: number;
    referenceHeight: number;
    pixelRatio: number;
    dimensionRatio: number;
    adaptiveScale: number;
  }>({
    referenceWidth: 3840,
    referenceHeight: 2160,
    pixelRatio: 1,
    dimensionRatio: 1,
    adaptiveScale: 1
  });

  // Update showDebugInfo when prop changes
  useEffect(() => {
    showDebugInfo.current = showDebugOverlay;
  }, [showDebugOverlay]);

  /**
   * Send update "up" to parent component so that the change can be sent to the
   * device and the rest of the interface can be updated with latest settings
   *
   * @param band
   */
  const onBandChange = (band) => {
    console.log("onBandChange", band)
    onEqChange([
      [band.label, "Frequency", band.fc],
      [band.label, "Gain", band.gain],
      [band.label, "Q", band.Q],
    ])
  }

  /**
   * When the page is resized, the layout needs to be updated
   *
   */
  const createInterface = () => {
    updateInterface()
    createSvgOverlay()
    reapplySvgBounds()
    // Ensure nodes with 0 gain are properly visualized as disabled
    eqBands.current.forEach((band) => {
      if (band.gain === 0) {
        deactivatePointer(band, 0)
      }
    })
  }

  /**
   * Update the interface by recalculating the curve and redrawing the canvas
   *
   * FIXME: This is potentially being called too oftern currently and could be
   * optimised.
   */
  const updateInterface = () => {
    // Calculate the maximum filter gain before calculating frequency response
    const highestGain = Math.max(
      0, // Include 0 as a minimum to avoid negative values when all bands are at 0 or negative
      ...eqBands.current.map(band => band.gain)
    )
    // Only update if value has changed
    if (highestGain !== maxFilterGain.current) {
      maxFilterGain.current = highestGain
      // Notify parent if callback exists
      if (onMaxFilterGainChange) {
        onMaxFilterGainChange(highestGain)
      }
    }
    
    calculateFrequencyResponse()
    eqBands.current.forEach((band) => {
      band.coords.point = {
        x: remapFreqToX(band.fc),
        y: remapGainToY(band.gain),
      }
    })
    drawCanvas()
  }

  /**
   * Draw the canvas elements including the frequency repsonse curve as well
   * as all of the grid lines and "non-interactive" EQ elements
   *
   */
  const drawCanvas = () => {
    canvasContext.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height)
    canvasContext.current.fillStyle = CHART_CONFIG.palette.background
    canvasContext.current.fillRect(0, 0, canvasRef.current.width, canvasRef.current.height)
    canvasContext.current.lineWidth = 1
    canvasContext.current.strokeStyle = CHART_CONFIG.palette.mainGrid
    canvasContext.current.strokeRect(
      CHART_CONFIG.margin,
      CHART_CONFIG.margin,
      canvasSize.current.areaWidth,
      canvasSize.current.areaHeight
    )

    const activeBand = eqBands.current[activeBandIdx.current]
    if (activeBand && activeBand.coords && activeBand.coords.limits) {
      canvasContext.current.fillStyle =
        "rgba(" +
        CHART_CONFIG.palette.backgroundDarkerRGB[0] +
        ", " +
        CHART_CONFIG.palette.backgroundDarkerRGB[1] +
        ", " +
        CHART_CONFIG.palette.backgroundDarkerRGB[2] +
        ", " +
        1 +
        ")"
      canvasContext.current.fillRect(
        activeBand.coords.limits.minX,
        activeBand.coords.limits.minY,
        activeBand.coords.limits.maxX - activeBand.coords.limits.minX,
        activeBand.coords.limits.maxY - activeBand.coords.limits.minY
      )
      canvasContext.current.fillStyle =
        "rgba(" +
        CHART_CONFIG.palette.limitsRGB[0] +
        ", " +
        CHART_CONFIG.palette.limitsRGB[1] +
        ", " +
        CHART_CONFIG.palette.limitsRGB[2] +
        ", " +
        0.07 * activeBandAreaOpacity.current +
        ")"
      canvasContext.current.fillRect(
        activeBand.coords.limits.minX,
        activeBand.coords.limits.minY,
        activeBand.coords.limits.maxX - activeBand.coords.limits.minX,
        activeBand.coords.limits.maxY - activeBand.coords.limits.minY
      )
    }

    // Format a frequency value in a human-readable format
    const fcFormatted = (fc: number): string => {
      if (fc < 1000) {
        return Math.round(fc).toString()
      } else {
        const rounded = Math.round(fc / 100) / 10
        return rounded % 1 === 0 ? `${rounded}k` : `${rounded.toFixed(1)}k`
      }
    }

    // Create an array of locations for the grid lines along the canvas
    const generateLogGrid = (min: number, max: number): number[] => {
      const minLog = Math.floor(Math.log10(min))
      const maxLog = Math.ceil(Math.log10(max))
      const gridLines = []
      for (let i = minLog; i <= maxLog; i++) {
        const base = Math.pow(10, i)
        gridLines.push(base)
        // Add more subdivisions: 1.5, 2, 3, 4, 5, 6, 7, 8, 9
        ;[1.5, 2, 3, 4, 5, 6, 7, 8, 9].forEach((step) => {
          const refinedLine = step * base
          if (refinedLine >= min && refinedLine <= max) {
            gridLines.push(refinedLine)
          }
        })
      }
      return gridLines
    }

    // vertical secondary grid lines & numbers
    const scaledFontSize = Math.round(CHART_CONFIG.fontSize * nodeScaleFactor.current)
    canvasContext.current.font = `${scaledFontSize}px Arial`
    canvasContext.current.strokeStyle = CHART_CONFIG.palette.secondaryGrid
    canvasContext.current.fillStyle = CHART_CONFIG.palette.secondaryGridText
    canvasContext.current.lineWidth = 1
    const gridLines = generateLogGrid(GLOBAL_LIMITS.freq.min, GLOBAL_LIMITS.freq.max)
    gridLines.forEach((fc) => {
      const x = remapFreqToX(fc)
      canvasContext.current.beginPath()
      canvasContext.current.moveTo(x, CHART_CONFIG.margin)
      canvasContext.current.lineTo(x, canvasSize.current.areaHeight + CHART_CONFIG.margin)
      canvasContext.current.stroke()
      // Only show labels for major grid lines (powers of 10 and 2, 3, 5 multiples)
      if (fc === Math.pow(10, Math.floor(Math.log10(fc))) || 
          [2, 3, 5].includes(fc / Math.pow(10, Math.floor(Math.log10(fc))))) {
        canvasContext.current.fillText(
          fcFormatted(fc),
          x,
          CHART_CONFIG.margin + canvasSize.current.areaHeight + 30
        )
      }
    })

    // vertical band lines + band freq text
    canvasContext.current.textAlign = "center"
    canvasContext.current.lineWidth = 2
    eqBands.current.forEach((band) => {
      const textX = band.coords.point.x
      const textY = CHART_CONFIG.margin + canvasSize.current.areaHeight + 30
      const txt = fcFormatted(band.fc)
      const w = canvasContext.current.measureText(txt).width
      canvasContext.current.fillStyle = CHART_CONFIG.palette.background
      canvasContext.current.fillRect(
        textX - 0.6 * w,
        textY - scaledFontSize,
        1.4 * w,
        1.5 * scaledFontSize
      )

      // Draw frequency text with rounded rectangle background
      const padding = 8 * nodeScaleFactor.current // Scale padding
      const cornerRadius = 8 * nodeScaleFactor.current // Scale corner radius
      const rectWidth = w + 2 * padding
      const rectHeight = scaledFontSize + padding
      const rectX = textX - rectWidth / 2
      const rectY = textY - scaledFontSize + padding / 2

      // Set bold font weight
      canvasContext.current.font = `bold ${scaledFontSize}px Arial`

      // Draw rounded rectangle
      canvasContext.current.fillStyle = "#3c3c3c"
      canvasContext.current.beginPath()
      canvasContext.current.moveTo(rectX + cornerRadius, rectY)
      canvasContext.current.lineTo(rectX + rectWidth - cornerRadius, rectY)
      canvasContext.current.quadraticCurveTo(rectX + rectWidth, rectY, rectX + rectWidth, rectY + cornerRadius)
      canvasContext.current.lineTo(rectX + rectWidth, rectY + rectHeight - cornerRadius)
      canvasContext.current.quadraticCurveTo(rectX + rectWidth, rectY + rectHeight, rectX + rectWidth - cornerRadius, rectY + rectHeight)
      canvasContext.current.lineTo(rectX + cornerRadius, rectY + rectHeight)
      canvasContext.current.quadraticCurveTo(rectX, rectY + rectHeight, rectX, rectY + rectHeight - cornerRadius)
      canvasContext.current.lineTo(rectX, rectY + cornerRadius)
      canvasContext.current.quadraticCurveTo(rectX + cornerRadius, rectY, rectX + cornerRadius, rectY)
      canvasContext.current.closePath()
      canvasContext.current.fill()

      // Draw text centered vertically and horizontally
      canvasContext.current.fillStyle = "#ffffff"
      canvasContext.current.fillText(txt, textX, rectY + (rectHeight / 2) + (scaledFontSize * 0.35))

      // Reset font weight for other text
      canvasContext.current.font = `${scaledFontSize}px Arial`

      canvasContext.current.beginPath()
      canvasContext.current.moveTo(band.coords.point.x, CHART_CONFIG.margin)
      canvasContext.current.lineTo(
        band.coords.point.x,
        canvasSize.current.areaHeight + CHART_CONFIG.margin
      )
      canvasContext.current.strokeStyle = CHART_CONFIG.palette.mainGrid
      canvasContext.current.stroke()
    })

    // horizontal grid lines
    canvasContext.current.textAlign = "right"
    canvasContext.current.strokeStyle = CHART_CONFIG.palette.secondaryGrid
    canvasContext.current.fillStyle = CHART_CONFIG.palette.secondaryGridText
    const gainStep = 5 // Change from 10 to 5 for more horizontal lines
    const gainLabels = []
    for (let gain = GLOBAL_LIMITS.gain.min; gain <= GLOBAL_LIMITS.gain.max; gain += gainStep) {
      gainLabels.push(gain)
    }
    gainLabels.forEach((gain) => {
      const gainY = remapGainToY(gain)
      canvasContext.current.fillText(
        gain,
        CHART_CONFIG.margin - CHART_CONFIG.yAxisPadding,
        gainY + 5
      )
      canvasContext.current.beginPath()
      canvasContext.current.moveTo(CHART_CONFIG.margin, gainY)
      canvasContext.current.lineTo(CHART_CONFIG.margin + canvasSize.current.areaWidth, gainY)
      canvasContext.current.stroke()
      
      // Add intermediate lines at 2.5dB intervals
      if (gain < GLOBAL_LIMITS.gain.max) {
        const intermediateY = remapGainToY(gain + gainStep/2)
        canvasContext.current.beginPath()
        canvasContext.current.setLineDash([2, 4])
        canvasContext.current.moveTo(CHART_CONFIG.margin, intermediateY)
        canvasContext.current.lineTo(CHART_CONFIG.margin + canvasSize.current.areaWidth, intermediateY)
        canvasContext.current.stroke()
        canvasContext.current.setLineDash([])
      }
    })

    // band limits
    canvasContext.current.lineWidth = 2
    if (activeBand) {
      canvasContext.current.strokeStyle =
        "rgba(" +
        CHART_CONFIG.palette.limitsRGB[0] +
        ", " +
        CHART_CONFIG.palette.limitsRGB[1] +
        ", " +
        CHART_CONFIG.palette.limitsRGB[2] +
        ", " +
        activeBandAreaOpacity.current +
        ")"
      canvasContext.current.setLineDash([6, 10])
      canvasContext.current.beginPath()
      canvasContext.current.moveTo(activeBand.coords.limits.minX, CHART_CONFIG.margin)
      canvasContext.current.lineTo(
        activeBand.coords.limits.minX,
        canvasSize.current.areaHeight + CHART_CONFIG.margin
      )
      canvasContext.current.stroke()
      canvasContext.current.beginPath()
      canvasContext.current.moveTo(activeBand.coords.limits.maxX, CHART_CONFIG.margin)
      canvasContext.current.lineTo(
        activeBand.coords.limits.maxX,
        canvasSize.current.areaHeight + CHART_CONFIG.margin
      )
      canvasContext.current.stroke()

      canvasContext.current.setLineDash([])
      canvasContext.current.shadowBlur = 10
      canvasContext.current.shadowColor = CHART_CONFIG.palette.activeFreqStroke
      canvasContext.current.strokeStyle = CHART_CONFIG.palette.activeFreqStroke
      canvasContext.current.beginPath()
      canvasContext.current.moveTo(activeBand.coords.point.x, CHART_CONFIG.margin)
      canvasContext.current.lineTo(
        activeBand.coords.point.x,
        canvasSize.current.areaHeight + CHART_CONFIG.margin
      )
      canvasContext.current.stroke()

      canvasContext.current.shadowBlur = 0
    }

    // curve itself
    canvasContext.current.strokeStyle = eqBands.current.every((band) => band.gain === 0)
        ? CHART_CONFIG.palette.mainGrid
        : CHART_CONFIG.palette.red
    canvasContext.current.lineWidth = 5

    // Create gradient for the fill
    const gradient = canvasContext.current.createLinearGradient(
        0,
        CHART_CONFIG.margin,
        0,
        canvasSize.current.areaHeight + CHART_CONFIG.margin
    )
    gradient.addColorStop(0, "rgba(255, 66, 62, 0.4)") // Red with 40% opacity at top
    gradient.addColorStop(0.55, "rgba(255, 66, 62, 0.1)") // Red with 10% opacity at middle
    gradient.addColorStop(0.85, "rgba(255, 66, 62, 0.05)") // Very subtle red near bottom
    gradient.addColorStop(1, "rgba(255, 66, 62, 0.02)") // Faint red glow at bottom

    // Draw the curve and fill
    canvasContext.current.beginPath()
    const coordinates = frequencyResponse.current.map((v) => ({
        x: remapFreqToX(v.frequency),
        y: remapGainToY(v.gain),
    }))
    
    // Start from the first point of the curve
    canvasContext.current.moveTo(coordinates[0].x, coordinates[0].y)
    
    // Draw the curve
    for (let i = 1; i < coordinates.length; i++) {
        canvasContext.current.lineTo(coordinates[i].x, coordinates[i].y)
    }
    
    // Close the path by drawing to bottom right, then bottom left
    canvasContext.current.lineTo(coordinates[coordinates.length - 1].x, canvasSize.current.areaHeight + CHART_CONFIG.margin)
    canvasContext.current.lineTo(coordinates[0].x, canvasSize.current.areaHeight + CHART_CONFIG.margin)
    canvasContext.current.closePath()
    
    // Fill with gradient
    canvasContext.current.fillStyle = gradient
    canvasContext.current.fill()
    
    // Redraw just the curve on top
    canvasContext.current.beginPath()
    canvasContext.current.moveTo(coordinates[0].x, coordinates[0].y)
    for (let i = 1; i < coordinates.length; i++) {
        canvasContext.current.lineTo(coordinates[i].x, coordinates[i].y)
    }
    canvasContext.current.stroke()

    // Draw crosshair for selected band
    if (selectedBandIdx.current !== -1) {
      const selectedBand = eqBands.current[selectedBandIdx.current]
      
      // Draw selection circle around the node (40% larger than node)
      canvasContext.current.shadowBlur = 12
      canvasContext.current.shadowColor = "rgba(200, 30, 30, 0.8)"
      canvasContext.current.strokeStyle = `rgba(200, 30, 30, ${crosshairOpacity.current})` // Darker red with fade
      canvasContext.current.lineWidth = 1
      canvasContext.current.beginPath()
      canvasContext.current.arc(
        selectedBand.coords.point.x,
        selectedBand.coords.point.y,
        CHART_CONFIG.pointerSize * 1.4 * nodeScaleFactor.current, // Apply scale factor to selection circle
        0,
        2 * Math.PI
      )
      canvasContext.current.stroke()
      
      // Calculate line endpoints
      const startX = selectedBand.coords.point.x
      const startY = selectedBand.coords.point.y
      const progress = crosshairProgress.current
      
      // Draw vertical crosshair line (frequency indicator) with animation
      canvasContext.current.shadowBlur = 12
      canvasContext.current.shadowColor = "rgba(200, 30, 30, 0.8)"
      canvasContext.current.strokeStyle = `rgba(200, 30, 30, ${crosshairOpacity.current})`
      canvasContext.current.lineWidth = 2
      canvasContext.current.beginPath()
      canvasContext.current.moveTo(startX, startY)
      
      // Animate line growing up and down from center
      const topY = CHART_CONFIG.margin
      const bottomY = canvasSize.current.areaHeight + CHART_CONFIG.margin
      const upProgress = Math.min(1, progress * 2)
      const downProgress = Math.max(0, progress * 2 - 1)
      
      const currentTopY = startY - (startY - topY) * upProgress
      const currentBottomY = startY + (bottomY - startY) * downProgress
      
      canvasContext.current.moveTo(startX, currentTopY)
      canvasContext.current.lineTo(startX, currentBottomY)
      canvasContext.current.stroke()

      // Draw horizontal crosshair line (gain indicator) with animation
      canvasContext.current.shadowBlur = 0
      canvasContext.current.strokeStyle = `rgba(74, 144, 226, ${crosshairOpacity.current})`
      canvasContext.current.lineWidth = 1
      canvasContext.current.beginPath()
      
      // Animate line growing left and right from center
      const leftX = CHART_CONFIG.margin
      const rightX = CHART_CONFIG.margin + canvasSize.current.areaWidth
      const leftProgress = Math.min(1, progress * 2)
      const rightProgress = Math.max(0, progress * 2 - 1)
      
      const currentLeftX = startX - (startX - leftX) * leftProgress
      const currentRightX = startX + (rightX - startX) * rightProgress
      
      canvasContext.current.moveTo(currentLeftX, startY)
      canvasContext.current.lineTo(currentRightX, startY)
      canvasContext.current.stroke()
    }

    // band points
    eqBands.current.forEach((band, bandIdx) => {
      // Add shadow to outer circle
      canvasContext.current.shadowColor = "rgba(0, 0, 0, 0.8)"
      canvasContext.current.shadowBlur = 8
      canvasContext.current.shadowOffsetY = 2
      
      // Apply node scale factor to the band scale
      const scale = (band.props.scale || 1) * nodeScaleFactor.current
      const scaledSize = CHART_CONFIG.pointerSize * scale
      
      // Draw outer circle
      canvasContext.current.fillStyle = band.props.outerColor
      canvasContext.current.beginPath()
      canvasContext.current.arc(
        band.coords.point.x,
        band.coords.point.y,
        scaledSize,
        0,
        2 * Math.PI
      )
      canvasContext.current.fill()
      
      // Reset shadow for inner circle and text
      canvasContext.current.shadowColor = "transparent"
      canvasContext.current.shadowBlur = 0
      canvasContext.current.shadowOffsetY = 0
      
      // Create gradient for inner circle
      const gradient = canvasContext.current.createLinearGradient(
        band.coords.point.x,
        band.coords.point.y - scaledSize * band.props.midSize,
        band.coords.point.x,
        band.coords.point.y + scaledSize * band.props.midSize
      )
      
      if (band.props.innerColor === CHART_CONFIG.palette.pointerMid) {
        gradient.addColorStop(0, CHART_CONFIG.palette.pointerMid)
        gradient.addColorStop(1, CHART_CONFIG.palette.pointerMidBottom)
      } else {
        gradient.addColorStop(0, CHART_CONFIG.palette.pointerMidGrey)
        gradient.addColorStop(1, CHART_CONFIG.palette.pointerMidGreyBottom)
      }
      
      // Draw inner circle with gradient
      canvasContext.current.fillStyle = gradient
      canvasContext.current.beginPath()
      canvasContext.current.arc(
        band.coords.point.x,
        band.coords.point.y,
        scaledSize * band.props.midSize,
        0,
        2 * Math.PI
      )
      canvasContext.current.fill()
      
      // Draw text (number or filter type)
      canvasContext.current.fillStyle = CHART_CONFIG.palette.pointerText
      canvasContext.current.textAlign = "center" // Set text alignment to center
      let txt
      if (bandIdx === activeBandIdx.current) {
        // Show filter type during drag
        txt = CHART_CONFIG.filterTypeSymbols[band.type]
        canvasContext.current.font = `bold ${Math.round(CHART_CONFIG.fontSize * nodeScaleFactor.current)}px Arial`
      } else {
        // Show number when not dragging
        txt = String(bandIdx + 1)
        canvasContext.current.font = `${Math.round(CHART_CONFIG.fontSize * nodeScaleFactor.current)}px Arial`
      }
      canvasContext.current.fillText(
        txt,
        band.coords.point.x,
        band.coords.point.y + 0.3 * scaledSize
      )
    })

    // At the very end of the function, after all other drawing:
    renderDebugInfo()
  }

  /**
   * Create an SVG overlay that contains all of the center points for the bands.
   * This overlay sits on top of the canvas element below which is charting the response curve
   */
  const createSvgOverlay = (): void => {
    svgRef.current.innerHTML = ""
    // Apply node scale factor to the handle radius as well
    const pointHandleRadius = CHART_CONFIG.pointerSize * 2 * nodeScaleFactor.current
    eqBands.current.forEach((band, bandIdx) => {
      createSvgDraggablePoint(band, bandIdx, pointHandleRadius)
    })
  }

  /**
   * Create the DOM SVG element for center point for a each band. These are
   * the points that are draggable by the user.
   *
   * NOTE: they are hidden SVG elements - the actual visible points themselves
   * are drawn onto the canvas
   *
   * @param band
   * @param bandIdx
   * @param pointHandleRadius
   */
  const createSvgDraggablePoint = (
    band: Band,
    bandIdx: number,
    pointHandleRadius: number
  ): void => {
    band.els.draggableEl = document.createElementNS("http://www.w3.org/2000/svg", "circle")
    svgRef.current.appendChild(band.els.draggableEl)
    gsap.set(band.els.draggableEl, {
      attr: {
        cx: band.coords.point.x,
        cy: band.coords.point.y,
        r: pointHandleRadius,
        fill: "transparent",
      },
    })

    // Add click handler for selection
    band.els.draggableEl.addEventListener("mousedown", (e) => {
      // Left click or middle click
      if (e.button === 0 || e.button === 1) {
        selectedBandIdx.current = bandIdx
        crosshairOpacity.current = 1
        crosshairProgress.current = 0
        gsap.to(crosshairProgress, {
          current: 1,
          duration: 0.4,
          ease: "power2.out",
          onUpdate: updateInterface
        })
        selectPointer(band)
        updateInterface()
      }
    })

    const drag = Draggable.create(band.els.draggableEl, {
      type: "x,y",
      onDragStart: () => {
        activeBandIdx.current = bandIdx
        selectedBandIdx.current = bandIdx
        crosshairOpacity.current = 1
        crosshairProgress.current = 0
        gsap.to(crosshairProgress, {
          current: 1,
          duration: 0.4,
          ease: "power2.out",
          onUpdate: updateInterface
        })
        selectPointer(band)
        updateInterface()
      },
      onDrag: () => {
        const offsetX: number = gsap.getProperty(band.els.draggableEl, "x") as number
        const offsetY: number = gsap.getProperty(band.els.draggableEl, "y") as number

        band.coords.point.x =
          +band.els.draggableEl.getAttribute("cx") + offsetX - CHART_CONFIG.margin
        band.fc = remapXtoFreq(band.coords.point.x)
        band.coords.point.y = +band.els.draggableEl.getAttribute("cy") + offsetY

        const newGain = remapYToGain(band.coords.point.y)
        if (newGain !== band.gain) {
          if (newGain === 0) {
            deactivatePointer(band)
          } else {
            if (band.gain === 0) {
              activatePointer(band)
            }
            // Cache any non-zero gain value
            onCacheGain(band.label, newGain)
          }
          band.gain = newGain
        }

        const boundaryDelta: number = Math.min(
          Math.abs(drag[0].minX - drag[0].x),
          Math.abs(drag[0].maxX - drag[0].x),
        );
        
        activeBandAreaOpacity.current = Math.pow(1 - Math.min(25, boundaryDelta) / 25, 2);

        updateInterface();
      },
      onDragEnd: () => {
        activeBandIdx.current = -1
        deselectPointer(band)
        const offsetX: number = gsap.getProperty(band.els.draggableEl, "x") as number
        const offsetY: number = gsap.getProperty(band.els.draggableEl, "y") as number
        gsap.set(band.els.draggableEl, {
          x: 0,
          y: 0,
          attr: {
            cx: +band.els.draggableEl.getAttribute("cx") + offsetX,
            cy: +band.els.draggableEl.getAttribute("cy") + offsetY,
          },
        })
        reapplySvgBounds()
        onBandChange(band)

        // Add pulse animation
        gsap.timeline()
          .to(band.props, {
            scale: 1.3,
            duration: 0.15,
            ease: "power2.out",
            onUpdate: updateInterface
          })
          .to(band.props, {
            scale: 1,
            duration: 0.4,
            ease: "elastic.out(1, 0.9)",
            onUpdate: updateInterface
          })
        
        // Fade out crosshair
        gsap.to(crosshairOpacity, {
          current: 0,
          duration: 0.5,
          ease: "power2.out",
          onUpdate: updateInterface,
          onComplete: () => {
            selectedBandIdx.current = -1
            crosshairProgress.current = 0
            updateInterface()
          }
        })
      },
    })

    band.els.draggableEl.addEventListener("dblclick", () => {
      handleBandToggle(band)
    })

    // Add wheel event listener for Q adjustment
    band.els.draggableEl.addEventListener(
      "wheel",
      (e) => {
        e.preventDefault()

        // Show crosshairs with animation
        selectedBandIdx.current = bandIdx
        crosshairOpacity.current = 1
        crosshairProgress.current = 0
        gsap.to(crosshairProgress, {
          current: 1,
          duration: 0.4,
          ease: "power2.out",
          onUpdate: updateInterface
        })
        updateInterface()

        // Determine step size based on current Q value
        let qStep
        if (band.Q < 1) {
          qStep = 0.1 // Fine control below 1
        } else if (band.Q < 10) {
          qStep = 0.5 // Medium control from 1-10
        } else {
          qStep = 1.0 // Coarse control above 10
        }

        // Adjust step based on wheel delta direction
        const direction = -Math.sign(e.deltaY)
        const newQ = Math.max(
          band.bandLimits.Q.min,
          Math.min(
            band.bandLimits.Q.max,
            // Round to 1 decimal place to avoid floating point errors
            Math.round((band.Q + direction * qStep) * 10) / 10
          )
        )

        if (newQ !== band.Q) {
          band.Q = newQ
          updateInterface()

          // Clear existing timer
          if (qAdjustmentTimer.current) {
            clearTimeout(qAdjustmentTimer.current)
          }

          // Set new timer to update device after wheel stops
          qAdjustmentTimer.current = setTimeout(() => {
            onBandChange(band)
            qAdjustmentTimer.current = null

            // Add pulse animation
            gsap.timeline()
              .to(band.props, {
                scale: 1.3,
                duration: 0.15,
                ease: "power2.out",
                onUpdate: updateInterface
              })
              .to(band.props, {
                scale: 1,
                duration: 0.4,
                ease: "elastic.out(1, 0.9)",
                onUpdate: updateInterface
              })

            // Fade out crosshair
            gsap.to(crosshairOpacity, {
              current: 0,
              duration: 0.5,
              ease: "power2.out",
              onUpdate: updateInterface,
              onComplete: () => {
                selectedBandIdx.current = -1
                crosshairProgress.current = 0
                updateInterface()
              }
            })
          }, 600)
        }
      },
      { passive: false }
    )
  }

  /**
   * Update the styling of the band center point
   *
   * @param band
   * @param duration
   */
  const selectPointer = (band: Band): void => {
    gsap.to(band.props, {
      duration: 0.1,
      outerColor: CHART_CONFIG.palette.pointerOuterBlue,
      midSize: 1.1 * CHART_CONFIG.pointerMidSize,
      onUpdate: updateInterface,
    })
  }

  /**
   * Update the styling of the band center point
   *
   * @param band
   * @param duration
   */
  const deselectPointer = (band: Band): void => {
    gsap.to(band.props, {
      duration: 0.3,
      outerColor: CHART_CONFIG.palette.pointerOuterWhite,
      midSize: CHART_CONFIG.pointerMidSize,
      onUpdate: updateInterface,
    })
  }

  /**
   * Update the styling of the band center point
   *
   * @param band
   * @param duration
   */
  const activatePointer = (band: Band): void => {
    gsap.to(band.props, {
      duration: 0.3,
      innerColor: CHART_CONFIG.palette.pointerMid,
      midSize: CHART_CONFIG.pointerMidSize,
      onUpdate: updateInterface,
    })
  }

  /**
   * Update the styling of the band center point
   *
   * @param band
   * @param duration
   */
  const deactivatePointer = (band: Band, duration: number = 0.3) => {
    gsap.to(band.props, {
      duration,
      innerColor: CHART_CONFIG.palette.pointerMidGrey, // This is the top color of the gray gradient
      midSize: 0.8 * CHART_CONFIG.pointerMidSize,
      onUpdate: updateInterface,
    })
  }

  /**
   * Updates the GSAP draggable boundaries for all of the bands so that the user
   * cannot place a band center point outside a certain limit
   *
   */
  const reapplySvgBounds = (): void => {
    console.log("reapplySvgBounds called, useBandLimits:", useBandLimits)
    let minX: number, minY: number, maxX: number, maxY: number
    minY = remapGainToY(GLOBAL_LIMITS.gain.min)
    maxY = remapGainToY(GLOBAL_LIMITS.gain.max)

    eqBands.current.forEach((band, bandIdx) => {
      if (useBandLimits) {
        console.log("Using band-to-band limits for band", bandIdx)
        // Use adjacent band positions as limits
        if (bandIdx === 0) {
          minX = remapFreqToX(GLOBAL_LIMITS.freq.min)
        } else {
          minX = eqBands.current[bandIdx - 1].coords.point.x
        }

        if (bandIdx === eqBands.current.length - 1) {
          maxX = remapFreqToX(GLOBAL_LIMITS.freq.max)
        } else {
          maxX = eqBands.current[bandIdx + 1].coords.point.x
        }
      } else {
        console.log("Using individual limits for band", bandIdx)
        // Use band's own frequency limits
        minX = remapFreqToX(band.bandLimits.freq.min)
        maxX = remapFreqToX(band.bandLimits.freq.max)
      }

      band.coords.limits = { minX, maxX, minY, maxY }
      console.log("Band", bandIdx, "limits:", { minX, maxX })
    })

    eqBands.current.forEach((band) => {
      const draggable = Draggable.get(band.els.draggableEl)
      draggable.applyBounds({
        minX: band.coords.limits.minX - band.coords.point.x,
        maxX: band.coords.limits.maxX - band.coords.point.x,
        minY: band.coords.limits.minY - band.coords.point.y,
        maxY: band.coords.limits.maxY - band.coords.point.y,
      })
    })
  }

  /**
   * Converts a point along a slider (v) back to to Q value, rounding to 3 decimal places
   *
   * @param {*} v
   * @returns
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const qFromSlider = (v: number): number => {
    let qNormalized = v / 100 // Use fixed height of 100
    qNormalized = Math.pow(qNormalized, 4)
    const q = GLOBAL_LIMITS.Q.min + qNormalized * (GLOBAL_LIMITS.Q.max - GLOBAL_LIMITS.Q.min)
    const roundedQ = Math.round(q * 1000) / 1000
    return Math.min(GLOBAL_LIMITS.Q.max, Math.max(GLOBAL_LIMITS.Q.min, roundedQ))
  }

  /**
   * Converts the band's Q value into a point along the slider (v)
   *
   * @param {*} Q
   * @returns
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const sliderFromQ = (Q: number): number => {
    let qNormalized = (Q - GLOBAL_LIMITS.Q.min) / (GLOBAL_LIMITS.Q.max - GLOBAL_LIMITS.Q.min)
    qNormalized = Math.pow(qNormalized, 1 / 4)
    return qNormalized * 100 // Use fixed height of 100
  }

  /**
   * Converts a point on the plot (x) back to a frequency value, rounding to the nearest integer
   *
   * @param {*} x
   * @returns {number}
   */
  const remapXtoFreq = (x: number): number => {
    const minLog = Math.log10(GLOBAL_LIMITS.freq.min)
    const maxLog = Math.log10(GLOBAL_LIMITS.freq.max)
    const logValue = (x / canvasSize.current.areaWidth) * (maxLog - minLog) + minLog
    const fc = Math.pow(10, logValue)
    const roundedFc = Math.round(fc)
    return Math.min(GLOBAL_LIMITS.freq.max, Math.max(GLOBAL_LIMITS.freq.min, roundedFc))
  }

  /**
   * Converts a band's frequency value to a point on the plot (x)
   *
   * @param {*} value
   * @returns
   */
  const remapFreqToX = (freqValue: number): number => {
    const minLog = Math.log10(GLOBAL_LIMITS.freq.min)
    const maxLog = Math.log10(GLOBAL_LIMITS.freq.max)
    const logValue = Math.log10(freqValue)
    const v = (logValue - minLog) / (maxLog - minLog)
    return CHART_CONFIG.margin + v * canvasSize.current.areaWidth
  }

  /**
   * Converts a point (y) on the plot back to a gain value, rounding to the nearest 1 decimal place
   *
   * @param {*} y
   * @returns {number}
   */
  const remapYToGain = (y: number): number => {
    const gain =
      GLOBAL_LIMITS.gain.min +
      ((canvasSize.current.areaHeight - y + CHART_CONFIG.margin) / canvasSize.current.areaHeight) *
        (GLOBAL_LIMITS.gain.max - GLOBAL_LIMITS.gain.min)
    // Change from 2 decimal places (100) to 1 decimal place (10)
    const roundedGain = Math.round(gain * 10) / 10
    return Math.min(GLOBAL_LIMITS.gain.max, Math.max(GLOBAL_LIMITS.gain.min, roundedGain))
  }

  /**
   * Converts a band's gain value to a point on the plot (y).
   *
   * @param {*} value
   * @returns
   */
  const remapGainToY = (gainValue: number): number => {
    return (
      canvasSize.current.areaHeight +
      CHART_CONFIG.margin -
      ((gainValue - GLOBAL_LIMITS.gain.min) / (GLOBAL_LIMITS.gain.max - GLOBAL_LIMITS.gain.min)) *
        canvasSize.current.areaHeight
    )
  }

  /**
   * Resizes the canvas and SVG elements to match the user's viewport
   *
   * @returns
   */
  const resizeCanvas = () => {
    canvasRef.current.width = containerRef.current.clientWidth * window.devicePixelRatio
    canvasRef.current.height = containerRef.current.clientHeight * window.devicePixelRatio
    gsap.set(svgRef.current, {
      attr: {
        viewBox: "0 0 " + canvasRef.current.width + " " + canvasRef.current.height,
      },
    })
    canvasSize.current.areaWidth = canvasRef.current.width - 2 * CHART_CONFIG.margin
    canvasSize.current.areaHeight = canvasRef.current.height - 2 * CHART_CONFIG.margin
    
    if (nodeScaling) {
      // Reference size is a 4K screen (3840x2160)
      const referenceWidth = 3840
      const referenceHeight = 2160
      
      // Get device pixel ratio (default to 1 if unavailable)
      const pixelRatio = window.devicePixelRatio || 1
      
      // Get container dimensions in CSS pixels
      const containerWidth = containerRef.current.clientWidth
      const containerHeight = containerRef.current.clientHeight
      
      // Calculate physical screen dimensions by multiplying by the pixel ratio
      const physicalScreenWidth = window.screen.width * pixelRatio
      const physicalScreenHeight = window.screen.height * pixelRatio
      
      // RELAXED CONDITION: If DPI Scaling > 1.0 and Physical Screen height > 1440
      // force the scale factor to 1.0
      const isHighDpiScreen = 
        pixelRatio > 1.0 && 
        physicalScreenHeight > 1440
      
      if (isHighDpiScreen) {
        // This is a high-DPI screen - force scale to 1.0
        nodeScaleFactor.current = 1.0
        
        // Store calculation values for debug
        debugScalingInfo.current = {
          referenceWidth,
          referenceHeight,
          pixelRatio,
          dimensionRatio: 1.0,
          adaptiveScale: 1.0
        }
        
        console.log("High-DPI screen detected - forcing scale to 1.0", {
          physicalScreen: `${physicalScreenWidth}×${physicalScreenHeight}`,
          logical: `${containerWidth}×${containerHeight}`,
          pixelRatio: pixelRatio.toFixed(2)
        })
      } 
      else {
        // For standard-DPI screens, use our adaptive scaling algorithm
        
        // Calculate the dimension ratio
        let widthRatio = containerWidth / referenceWidth
        let heightRatio = containerHeight / referenceHeight
        
        // For high-DPI displays, compensate for the OS scaling by multiplying by pixel ratio
        if (pixelRatio > 1) {
          widthRatio *= pixelRatio
          heightRatio *= pixelRatio
        }
        
        // Use the smaller ratio to ensure elements fit within the container
        const dimensionRatio = Math.min(widthRatio, heightRatio)
        
        // Set scaling limits
        const minScale = 0.5 // Prevents nodes from becoming too small on tiny screens
        const maxScale = 1.5 // Prevents nodes from becoming excessively large on huge screens
        
        // Apply scaled factor based on screen size relative to reference 4K dimensions
        let adaptiveScale
        if (dimensionRatio < 1) {
          // For smaller screens: More gradual scaling using square root
          adaptiveScale = Math.sqrt(dimensionRatio)
        } else {
          // For larger screens (5K+): More conservative linear scaling
          adaptiveScale = 1.0 + 0.5 * (dimensionRatio - 1)
        }
        
        // Apply the new scale factor with both minimum and maximum limits
        nodeScaleFactor.current = Math.max(minScale, Math.min(maxScale, adaptiveScale))
        
        // Store calculation values for debug display
        debugScalingInfo.current = {
          referenceWidth,
          referenceHeight,
          pixelRatio,
          dimensionRatio,
          adaptiveScale
        }
      }
      
      // Only log when the scale changes significantly to reduce console spam
      const currentScale = nodeScaleFactor.current
      if (lastLoggedScale.current === null || Math.abs(lastLoggedScale.current - currentScale) > 0.01) {
        lastLoggedScale.current = currentScale
        console.log(
          "Node scaling applied:",
          {
            scale: currentScale.toFixed(3),
            screenSize: `${containerWidth}x${containerHeight}`,
            pixelRatio: pixelRatio.toFixed(2),
            physicalScreen: `${Math.round(physicalScreenWidth)}×${Math.round(physicalScreenHeight)}`,
            physicalContainer: `${Math.round(containerWidth * pixelRatio)}×${Math.round(containerHeight * pixelRatio)}`,
            isHighDpiScreen: isHighDpiScreen
          }
        )
      }
    } else {
      // If scaling is disabled, use a constant size (1.0)
      nodeScaleFactor.current = 1.0
    }
  }

  /**
   * Create a bi-quad filter from the browser Audio API, used to calculate
   * the frequency response curve.
   *
   * @param band
   * @returns
   */
  const createBiquadFilter = (band: Band) => {
    const filter = audioContext.current.createBiquadFilter()
    filter.frequency.value = band.fc
    filter.gain.value = band.gain
    filter.Q.value = band.Q
    filter.type = band.type
    return filter
  }

  /**
   * Recalculate the frequency response curve
   *
   * This is an array of gain and frequency values that plots the curve based
   * on the settings supplied in the eqBands object.
   *
   * @returns
   */
  const calculateFrequencyResponse = (): void => {
    const pointsNumber = canvasSize.current.areaWidth
    const response = new Float32Array(pointsNumber).fill(0)
    const frequencies = new Float32Array(pointsNumber)
    const magResponse = new Float32Array(pointsNumber)
    const phaseResponse = new Float32Array(pointsNumber)

    // get an array of points to calculate freq => gain
    const step = Math.pow(GLOBAL_LIMITS.freq.max / GLOBAL_LIMITS.freq.min, 1 / (pointsNumber - 1))
    for (let i = 0; i < pointsNumber; i++) {
      frequencies[i] = GLOBAL_LIMITS.freq.min * Math.pow(step, i)
    }

    // accumulate the gain response through all the EQ filters
    eqBands.current.forEach((band) => {
      const filter = createBiquadFilter(band)
      filter.getFrequencyResponse(frequencies, magResponse, phaseResponse)
      for (let i = 0; i < pointsNumber; i++) {
        // to db
        response[i] += 20 * Math.log10(magResponse[i])
      }
    })

    // Calculate the peak (maximum) gain in the response
    let maxGain = -Infinity
    for (let i = 0; i < pointsNumber; i++) {
      if (response[i] > maxGain) {
        maxGain = response[i]
      }
    }
    
    // Update the peak gain ref and notify parent if callback exists
    if (maxGain !== peakGain.current) {
      peakGain.current = maxGain
      if (onPeakGainChange) {
        onPeakGainChange(maxGain)
      }
    }

    frequencyResponse.current = Array.from(frequencies).map((freq, i) => ({
      frequency: freq,
      gain: response[i],
    }))
  }

  /**
   * Handle double-click on a band point
   *
   * @param band The band being toggled
   */
  const handleBandToggle = (band) => {
    if (band.gain === 0) {
      // Enabling - check for cached gain
      const cachedGain = cachedGains[band.label]
      if (typeof cachedGain === "number") {
        band.gain = cachedGain // Immediately set the gain
        onEqChange([[band.label, "Gain", cachedGain]])
        onClearCache(band.label)
        // Update visual state
        activatePointer(band)
        // Animate the visual representation
        gsap.from(band.props, {
          duration: 0.25,
          midSize: 0.8 * CHART_CONFIG.pointerMidSize,
          onUpdate: updateInterface,
        })
      }
    } else {
      // Disabling - cache current gain
      const currentGain = band.gain
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onCacheGain(band.label, currentGain)
      band.gain = 0 // Immediately set the gain
      onEqChange([[band.label, "Gain", 0]])
      // Update visual state
      deactivatePointer(band)
      // Animate the visual representation
      gsap.from(band.props, {
        duration: 0.25,
        midSize: CHART_CONFIG.pointerMidSize,
        onUpdate: updateInterface,
      })
    }
  }

  // Deselect when clicking outside
  useEffect(() => {
    const handleMouseUp = (e: MouseEvent) => {
      // Only fade if we have a selected band and we're not clicking on a band point
      if (selectedBandIdx.current !== -1 && !(e.target instanceof SVGCircleElement)) {
        const selectedBand = eqBands.current[selectedBandIdx.current]
        
        // Ensure scale is initialized
        if (typeof selectedBand.props.scale === 'undefined') {
          selectedBand.props.scale = 1
        }
        
        // Pulse animation
        gsap.timeline()
          .to(selectedBand.props, {
            scale: 1.3,
            duration: 0.15,
            ease: "power2.out",
            onUpdate: updateInterface
          })
          .to(selectedBand.props, {
            scale: 1,
            duration: 0.4,
            ease: "elastic.out(1, 0.9)",
            onUpdate: updateInterface
          })

        // Fade out crosshair
        gsap.to(crosshairOpacity, {
          current: 0,
          duration: 0.5,
          ease: "power2.out",
          onUpdate: updateInterface,
          onComplete: () => {
            selectedBandIdx.current = -1
            crosshairProgress.current = 0
            updateInterface()
          }
        })
      }
    }

    document.addEventListener("mouseup", handleMouseUp)
    
    return () => {
      document.removeEventListener("mouseup", handleMouseUp)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * Initialise the canvas and SVG elements on first page-load
   *
   */
  useEffect(() => {
    gsap.registerPlugin(Draggable)

    audioContext.current = new AudioContext()
    canvasContext.current = canvasRef.current.getContext("2d")

    const onResize = () => {
      if (containerRef.current.clientWidth > 100) {
        resizeCanvas()
        createInterface()
      }
    }
    resizeCanvas()

    window.addEventListener("resize", onResize)

    const resizeObserver = new ResizeObserver(onResize)
    resizeObserver.observe(containerRef.current)

    return () => {
      window.removeEventListener("resize", onResize)
      resizeObserver.disconnect()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * Update our internal representation of the eq bands when the "outside" settings
   * from the app changes. This can happen from numerous user interactions:
   *
   * - The EQ table is updated
   * - A preset is loaded
   * - A preset is deleted
   * - A link it loaded
   * - etc.
   */
  useEffect(() => {
    if (typeof eqSettings !== "undefined") {
      console.log(
        "Before update - eqBands:",
        eqBands.current.map((b) => ({
          label: b.label,
          gain: b.gain,
          fc: b.fc,
          Q: b.Q,
        }))
      )
      console.log("Incoming eqSettings:", eqSettings)

      eqBands.current = Object.entries(eqSettings)
        .filter(([key, value]) => key !== "Preamp")
        .map(([key, value]) => {
          // Find existing band if it exists
          const existingBand = eqBands.current?.find((band) => band.label === key)

          // Update existing band with new values
          if (existingBand) {
            return {
              ...existingBand,
              fc: value["Frequency"]["Current"], // Update frequency
              Q: value["Q"]["Current"], // Update Q
              bandLimits: {
                freq: { min: value["Frequency"]["Min"], max: value["Frequency"]["Max"] },
                gain: { min: value["Gain"]["Min"], max: value["Gain"]["Max"] },
                Q: { min: value["Q"]["Min"], max: value["Q"]["Max"] },
              },
            }
          }

          // For new bands, create with initial values
          return {
            fc: value["Frequency"]["Current"],
            gain: value["Gain"]["Current"],
            Q: value["Q"]["Current"],
            type: key.toLowerCase().replace(/\s\d+$/, "") as BiquadFilterType,
            label: key,
            bandLimits: {
              freq: { min: value["Frequency"]["Min"], max: value["Frequency"]["Max"] },
              gain: { min: value["Gain"]["Min"], max: value["Gain"]["Max"] },
              Q: { min: value["Q"]["Min"], max: value["Q"]["Max"] },
            },
            els: {},
            coords: {},
            props: {
              midSize: CHART_CONFIG.pointerMidSize,
              innerColor: value["Gain"]["Current"] === 0 ? CHART_CONFIG.palette.pointerMidGrey : CHART_CONFIG.palette.pointerMid,
              outerColor: CHART_CONFIG.palette.pointerOuterWhite,
              lastGain: 0,
              scale: 1
            },
          }
        })

      // Handle explicit changes from table
      const explicitChanges = eqBands.current.map((band) => {
        const incomingSettings = eqSettings[band.label]
        if (incomingSettings && incomingSettings["Gain"]["Current"] !== band.gain) {
          const newGain = incomingSettings["Gain"]["Current"]
          console.log(`Explicit change for ${band.label} - Current: ${band.gain}, New: ${newGain}`)

          if (newGain === 0) {
            deactivatePointer(band, 0)
          } else {
            if (band.gain === 0) {
              activatePointer(band)
            }
            // Cache any non-zero gain value
            onCacheGain(band.label, newGain)
          }
          band.gain = newGain
        }
        return band
      })
      eqBands.current = explicitChanges

      console.log(
        "After update - eqBands:",
        eqBands.current.map((b) => ({
          label: b.label,
          gain: b.gain,
          fc: b.fc,
          Q: b.Q,
        }))
      )

      if (svgRef.current && canvasRef.current) {
        createInterface()
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eqSettings, mode])

  useEffect(() => {
    if (svgRef.current && canvasRef.current) {
      reapplySvgBounds()
      createInterface()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useBandLimits])

  /**
   * Get the peak gain value in the current frequency response
   * This can be called externally if needed
   * 
   * @returns {number} The maximum gain value in dB
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const getPeakGain = (): number => {
    return peakGain.current
  }

  /**
   * Renders debug information about screen metrics directly on the canvas
   * This helps diagnose scaling issues across different displays
   */
  const renderDebugInfo = () => {
    if (!showDebugInfo.current || !canvasRef.current || !canvasContext.current) return
    
    // Set up text formatting
    const ctx = canvasContext.current
    ctx.save()
    
    // Create a semi-transparent background
    ctx.fillStyle = 'rgba(0, 0, 0, 0.9)' // Slightly more opaque for better readability
    ctx.fillRect(10, 10, 480, 480) // Make slightly larger to fit all content
    
    // Add a border
    ctx.strokeStyle = '#4488ff'
    ctx.lineWidth = 2
    ctx.strokeRect(10, 10, 480, 480)
    
    // Title
    ctx.font = 'bold 16px monospace'
    ctx.fillStyle = '#4488ff'
    ctx.textAlign = 'center'
    ctx.fillText('DISPLAY DIAGNOSTICS', 240, 30)
    
    // Reset for content
    ctx.font = '14px monospace'
    ctx.fillStyle = '#ffffff'
    ctx.textAlign = 'left'
    
    // Function to create labeled sections
    const drawSection = (title: string, metrics: Record<string, any>, startY: number): number => {
      ctx.fillStyle = '#88ccff'
      ctx.fillText(title, 20, startY)
      ctx.fillStyle = '#ffffff'
      
      let y = startY + 20
      Object.entries(metrics).forEach(([key, value]) => {
        ctx.fillText(`${key}: ${value}`, 30, y)
        y += 20
      })
      return y + 10
    }
    
    // Display metrics in organized sections
    let y = 50
    
    // Section 1: System & Browser
    const systemMetrics = {
      'User Agent': navigator.userAgent.slice(0, 60) + (navigator.userAgent.length > 60 ? '...' : ''),
      'Device Pixel Ratio': window.devicePixelRatio.toFixed(2),
      'Platform': navigator.platform
    }
    y = drawSection('SYSTEM & BROWSER', systemMetrics, y)
    
    // Section 2: Screen & Window
    const pixelRatio = window.devicePixelRatio || 1
    const screenMetrics = {
      'Screen Dimensions': `${window.screen.width}×${window.screen.height}`,
      'Physical Screen': `${Math.round(window.screen.width * pixelRatio)}×${Math.round(window.screen.height * pixelRatio)}`,
      'Window (inner)': `${window.innerWidth}×${window.innerHeight}`,
      'Window (outer)': `${window.outerWidth}×${window.outerHeight}`,
    }
    y = drawSection('SCREEN & WINDOW', screenMetrics, y)
    
    // Section 3: Canvas & Container
    const canvasMetrics = {
      'Canvas Logical Size': `${canvasRef.current.clientWidth}×${canvasRef.current.clientHeight}`,
      'Canvas Actual Size': `${canvasRef.current.width}×${canvasRef.current.height}`,
      'Canvas Draw Area': `${canvasSize.current.areaWidth}×${canvasSize.current.areaHeight}`,
      'Container Size': `${containerRef.current?.clientWidth || 0}×${containerRef.current?.clientHeight || 0}`,
      'Container Physical': `${Math.round((containerRef.current?.clientWidth || 0) * pixelRatio)}×${Math.round((containerRef.current?.clientHeight || 0) * pixelRatio)}`,
    }
    y = drawSection('CANVAS & CONTAINER', canvasMetrics, y)
    
    // Section 4: Scaling Calculation Details
    const scalingInfo = debugScalingInfo.current
    
    // Calculate physical screen dimensions
    const physicalScreenWidth = window.screen.width * pixelRatio
    const physicalScreenHeight = window.screen.height * pixelRatio
    
    // RELAXED CONDITION: DPI Scaling > 1.0 and Physical Screen height > 1440
    const isHighDpiScreen = 
      pixelRatio > 1.0 && 
      physicalScreenHeight > 1440
    
    const scalingMetrics = {
      'Reference (4K)': `${scalingInfo.referenceWidth}×${scalingInfo.referenceHeight}`,
      'Physical Screen': `${Math.round(physicalScreenWidth)}×${Math.round(physicalScreenHeight)}`,
      'DPI > 1 & Height > 1440': isHighDpiScreen ? 'Yes (Scale forced to 1.0)' : 'No',
      'Dimension Ratio': scalingInfo.dimensionRatio.toFixed(3),
      'Adaptive Scale': scalingInfo.adaptiveScale.toFixed(3),
      'Final Node Scale': nodeScaleFactor.current.toFixed(3),
    }
    y = drawSection('SCALING CALCULATION', scalingMetrics, y)
    
    // Add note about how to interpret
    ctx.fillStyle = '#88ff88'
    ctx.fillText('Scaling Interpretation:', 20, y)
    y += 20
    
    ctx.fillStyle = '#ffffff'
    ctx.fillText('• Node Scale = 1.0 means 4K reference sizing', 30, y)
    y += 20
    ctx.fillText('• High-DPI displays (height > 1440): Scale forced to 1.0', 30, y)
    y += 20
    ctx.fillText('• Physical Screen = Screen Dimensions × Device Pixel Ratio', 30, y)
    
    ctx.restore()
  }

  if (!eqSettings) {
    return <></>
  }

  return (
    <div id="equaliser-container" ref={containerRef} className="relative h-full w-full">
      <canvas ref={canvasRef} className="h-full w-full" />
      <svg ref={svgRef} className="absolute left-0 top-0 h-full w-full" />
    </div>
  )
}

export default EqualizerCanvas
